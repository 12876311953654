import React from 'react';
import './InvoiceList.css';
import Invoice from './Invoice';

export default function InvoiceList({ invoices, selectedInvoice, setSelectedInvoice }) {
  const invoiceList = invoices.map(invoice => (
      <Invoice key={invoice.InvoiceNumber} invoice={invoice} selectedInvoice={selectedInvoice} setSelectedInvoice={setSelectedInvoice}></Invoice>
  ))
  
  return (<div className="list-container"><ul className="item-list">{invoiceList}</ul></div>);
}
