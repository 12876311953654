import React from 'react'
import './SearchResultReceipt.css'

export default function SearchResultReceipt({ record }) {
    var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

  return (
    <div className="search-result-receipt">
        <span>{record.ReceiptID} </span><span> {record.ReceiptRef} </span><span> {formatter.format(record.AmountOutstanding)} </span><span> {new Date(record.ReceiptDate).toLocaleDateString('en-GB')} </span>
        <span> {record.AgencyId} </span><span>{formatter.format(record.Amount)}</span>
    </div>
  )
}