import React, {useState} from 'react'
import './AllocationsDisplay.css'

export default function AllocationsDisplay({allocation, setDataChanged}) {
    var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
    
        const deleteAlloc = async () => {
            console.log('delete alloc running')
            if (!allocation) return
            try {
                const url=`/api/receiptallocations/${allocation.AllocationID}`
                const res = await fetch(url, {method: 'DELETE', body: {}})
                if (res.ok) {
                    
                    setDataChanged(Date.now())
                }
            } catch (error) {
                console.log(error)
            }
            
        }

  return (
    <div key={allocation.AllocationID} className='allocation-display-row'>
        <button className='delete-allocation-button' onClick={deleteAlloc}>Delete Allocation</button>
        <span className='allocation-receipt-date-display'>{new Date(allocation.ReceiptDate).toLocaleDateString('en-GB')}</span>
        <span className='allocation-amount-display'>Amount: {formatter.format(allocation.Amount)}</span>
        <span className='allocation-receipt-ref-display'>Ref: {allocation.ReceiptRef}</span>
        
    </div>
  )
}
