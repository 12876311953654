import React, {useState, useEffect} from 'react'
import './SearchReceipt.css'
import SearchResultReceipt from './SearchResultReceipt';

export default function SearchReceipt({ name, user }) {
  const baseUrl = `/api/receipts/${user.companyId}`
  const xlUrl = `/api/receipts/export/excel/${user.companyId}`  
  const [criteria, setCriteria] = useState({agency: "", receiptdatefrom: "", receiptdateto: "", receiptRef: ""})
    const [transactions, setTransactions] = useState();

    async function search() {
        console.log("criteria", criteria)
        let searchString = "";
        if (criteria.agency) searchString = `agency=${criteria.agency}`
        // if (criteria.advertiser) searchString = (searchString ? searchString + '&' : "" ) + `advertiser=${criteria.advertiser}` 
        // if (criteria.product) searchString = (searchString ? searchString + '&' : "" ) + `product=${criteria.product}`
        // if (criteria.invoicenumber) searchString = (searchString ? searchString + '&' : "" ) + `invoicenumber=${criteria.invoicenumber}`
        // if (criteria.order) searchString = (searchString ? searchString + '&' : "" ) + `order=${criteria.order}`
        // if (criteria.channel) searchString = (searchString ? searchString + '&' : "" ) + `channel=${criteria.channel}`
        if (criteria.receiptdatefrom) searchString = (searchString ? searchString + '&' : "" ) + `receiptdatefrom=${criteria.receiptdatefrom}`
        if (criteria.receiptdateto) searchString = (searchString ? searchString + '&' : "" ) + `receiptdateto=${criteria.receiptdateto}`

        const url = baseUrl + '?' + searchString
        const data = await fetch(url)
        const results = await data.json()

        console.log("results", results)
        setTransactions(results.receipts)
        
    }

    function xlExport() {
      console.log("criteria", criteria)
        let searchString = "";
        if (criteria.agency) searchString = `agency=${criteria.agency}`
        // if (criteria.advertiser) searchString = (searchString ? searchString + '&' : "" ) + `advertiser=${criteria.advertiser}` 
        // if (criteria.product) searchString = (searchString ? searchString + '&' : "" ) + `product=${criteria.product}`
        // if (criteria.invoicenumber) searchString = (searchString ? searchString + '&' : "" ) + `invoicenumber=${criteria.invoicenumber}`
        // if (criteria.order) searchString = (searchString ? searchString + '&' : "" ) + `order=${criteria.order}`
        // if (criteria.channel) searchString = (searchString ? searchString + '&' : "" ) + `channel=${criteria.channel}`
        if (criteria.receiptdatefrom) searchString = (searchString ? searchString + '&' : "" ) + `receiptdatefrom=${criteria.receiptdatefrom}`
        if (criteria.receiptdateto) searchString = (searchString ? searchString + '&' : "" ) + `receiptdateto=${criteria.receiptdateto}`

        const url = xlUrl + '?' + searchString
        console.log('fetching excel link')
        // fetch(url)
        window.location.href = url;
    }

  return (
    <>
    <div className="search-box">
        {/* SearchCriteria */}
        <div>{name}</div>
        <div className="search-criteria">
          <input type="text" onChange={e => setCriteria({...criteria, agency: e.target.value})} placeholder="Agency"></input>
          <label for="fromdate">From Date</label>
          <input type="date" id="fromdate" onChange={e => setCriteria({...criteria, receiptdatefrom: e.target.value})} placeholder="Receipt Date From"></input>
          <label for="todate">To Date</label>
          <input type="date" id="todate" onChange={e => setCriteria({...criteria, receiptdateto: e.target.value})} placeholder="Receipt Date To"></input>
          <input type="text" onChange={e => setCriteria({...criteria, receiptRef: e.target.value})} placeholder="Receipt Ref"></input>
        </div>
        
        <button onClick={search}>Search</button>
        <button onClick={xlExport}>Excel Export</button>
        <div className="search-result-receipt-header-titles">
            <span>ReceiptID</span><span>Receipt Ref</span><span>Outstanding</span><span>Receipt Date</span><span>Agency ID</span><span>Receipt Amount</span>
        </div>
        {transactions ? [...transactions].map(record => <SearchResultReceipt key={record.ReceiptID} record={record} />) : ""}
        

    </div>
        
    </>
    
  )
}