import React, { useState } from 'react'

export default function ManualReceiptInvoice( { saleshouseId, agencyId, closeModal, setDataChanged }) {
    const date = new Date();
    
    const [formData, setFormData] = useState({
        SaleshouseId: saleshouseId,
        AgencyID: agencyId,
        Gross: 0,
        VAT: 0,
        Nett: 0,
        InvoiceDate: date.setHours(0, 0, 0, 0),
        Ref: "",
        vatRate: 0.2,
        grossChangedLast: false
    })

    const SaveManualInvoice = async () => {
        
        const invoiceData = {
            manualInvoices: [{...formData}]
        }
        console.log(invoiceData)
        try {
            
            const url = `/api/manualreceiptinvoices/${saleshouseId}/${agencyId}`;
            const res = await fetch(url, {
                method: "post",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(invoiceData)
              })
              
            // if (res.ok) {

            //     //setMissingCustomers(missingCustomers.filter(cust => cust.AgencyId != customer.AgencyId));
            //     // setCustomers([...customers, newItem]);
            // }   
        } catch (error){
            console.log(error)
        }
        setDataChanged(Date.now())
        closeModal();
    }
    function changeVAT(e) {
        //Set VAT rate
        let newData = formData
        switch (e.target.value) {
            case "0":
                newData.vatRate = 0
                break;
            case "0.2":
                newData.vatRate = 0.2
                break;
        }
        //set VAT amounts based on which value was typed
        if (newData.grossChangedLast){
            newData.Nett = Math.round((newData.Gross / (1 + formData.vatRate)) * 100) / 100 //round to 2dp
        } else {
            newData.Gross = Math.round((newData.Nett * (1 + formData.vatRate)) * 100) / 100 //round to 2dp
        }
        newData.VAT = Math.round((newData.Gross - newData.Nett) * 100) / 100
        
        setFormData({...newData})
        
    }
    function changeGross(e) {
        //Add Gross and recalculate vat and nett
        let newData = formData;
        newData.Gross = e.target.value
        newData.Nett = Math.round((newData.Gross / (1 + formData.vatRate)) * 100) / 100 //round to 2dp
        newData.VAT = Math.round((newData.Gross - newData.Nett) * 100) / 100
        newData.grossChangedLast = true
        setFormData({...newData})
    }
    
    function changeNett(e) {
        //Add Nett and recalculate vat and gross
        let newData = formData;
        newData.Nett = e.target.value
        newData.Gross = Math.round((newData.Nett * (1 + formData.vatRate)) * 100) / 100 //round to 2dp
        newData.VAT = Math.round((newData.Gross - newData.Nett) * 100) / 100
        newData.grossChangedLast = false
        setFormData({...newData})
    }

    return (
    
        <>
        <h4>Add a Manual Invoice</h4>
        <div>
            <label htmlFor='nett'>Nett</label>
            <input className="receipt-addition" type="number" min="0" step="0.01" value={formData.Nett} placeholder="Nett" onChange={(e)=>changeNett(e)} name="nett" id="nett" />
            <select name="vatrate" id="vatrate" onChange={(e)=>changeVAT(e)}>
                <option value="0.2">20% VAT</option> 
                <option value="0">0% VAT</option>   
            </select><label>   £{formData.VAT} VAT</label><br/>
            <label htmlFor='gross'>Gross</label>
            <input className="receipt-addition" type="number" min="0" step="0.01" value={formData.Gross} placeholder="Gross" onChange={(e)=>changeGross(e)} name="gross" id="gross" />
            <input className="receipt-addition" type="date" value={formData.InvoiceDate} placeholder="InvoiceDate" onChange={(e)=>setFormData({...formData, InvoiceDate: e.target.value})} name="invoicedate" id="invoicedate" />
            <input className="receipt-addition" type="text" value={formData.ReceiptRef} placeholder="Invoice Reference" onChange={(e)=>setFormData({...formData, Ref: e.target.value})} name="invoiceref" id="invoiceref" />
        </div>
        {formData.Gross > 0 ? <button className="receipt-submit-button" onClick={SaveManualInvoice} >Save Manual Invoice</button> : <button className="receipt-submit-button" disabled >Save Manual Invoice</button>}
    </>
  )
}
