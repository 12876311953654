import React, { useState, useEffect } from 'react'
import './MissingCustomers.css';
import MissingCustomer from './MissingCustomer';

export default function MissingCustomers({missingCustomers, saleshouseId, customers, setMissingCustomers, setCustomers, setDataChanged}) {
    // const  [ missingCustomers, setMissingCustomers ] = useState([])

    // useEffect(() => {
    //     //console.log("useEffect running");
    //     //console.log("user from useEffect", user);
    
    //     // });
    //     getMissingCustomers();
    //   }, []);
    
    //   const getMissingCustomers = async () => {
    //     try {
    //       const url = `http://localhost:5000/customers/${saleshouseId}/missing`
    //       const res = await fetch(url)
    //       const data =  await res.json();
          
    //       setMissingcustomers(data.customers)
    //     } catch (error) {
    //       console.log(error)
    //     }
    //   }
    useEffect(() => {

    },[missingCustomers])

  return ((!missingCustomers || missingCustomers.length == 0) ? null :
    <>
      <h3>Missing Media Buyers</h3>
        {/* {missingCustomers.map(mc => <MissingCustomer key={mc.agencyId} missingCustomer={mc} salehouseId={saleshouseId}></MissingCustomer>)}  */}
        <div className="customers-container">
          {missingCustomers.map(cust => <MissingCustomer customer={cust} customers={customers} setCustomers={setCustomers} missingCustomers={missingCustomers} setMissingCustomers={setMissingCustomers} missingCustomer={cust} key={cust.AgencyId} saleshouseId={cust.SaleshouseId} setDataChanged={setDataChanged} ></MissingCustomer>)}
        </div>
      
    </>
  )
}
