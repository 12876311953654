import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useParams } from "react-router-dom"
//import logo from './logo.svg';
import './App.css';
import Header from './Header';
import Login from './Login';
import Projects from './Projects';
import Sales from './Sales';
import Purchases from './Purchases';
import ReceiptsAnalysis from './ReceiptsAnalysis';
import PaymentsAnalysis from './PaymentsAnalysis';
import Customers from './Customers';
import Suppliers from './Suppliers';
import Receipts from './Receipts';
import Payments from './Payments';
import Search from './Search';



function App() {

  const analysis = {
    customers: [
      {Name: "Agency 301903", month1: "4523.12", month2: "2302.12", month3: "12328.12", unallocated: "0"},
      {Name: "Dummy Agency", month1: "1397.37", month2: "2648.84", month3: "0", unallocated: "0"}
    ],
    totals: {Name: "Total", month1: "5920.49", month2: "4950.96", month3: "12328.12", unallocated: "0"}

  }

  const payanalysis = {
    customers: [
      {Name: "Agency 301903", month1: "3844.65", month2: "1956.80", month3: "10478.90", unallocated: "0"},
      {Name: "Dummy Agency", month1: "1187.45", month2: "2251.51", month3: "0", unallocated: "0"}
    ],
    totals: {Name: "Total", month1: "5032.10", month2: "4208.31", month3: "10478.90", unallocated: "0"}

  }

  
  const [user, setUser] = useState(() => {
    const saved = localStorage.getItem("user");
    const initialValue = JSON.parse(saved);
    return initialValue;
  });
  // const [user, setUser] = useState({ username: "media15", password: '123', companyName: 'MEDIA 15', companyId: 37 })
  const [projects, setProjects] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [receiptInvoices, setReceiptInvoices] = useState([]);
  const [payments, setPayments] = useState([]);
  const [paymentInvoices, setPaymentInvoices] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [missingCustomers, setMissingCustomers] = useState([])
  const [suppliers, setSuppliers] = useState([]);
  const [missingSuppliers, setMissingSuppliers] = useState([])
  const [selectedInvoice, setSelectedInvoice] = useState()

  // const login = () => {
  //   const data = { username: userName, password: password }
  //   const options = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify(data),
  //     withCredentials: true
  //   }
  //   fetch("http://localhost:5000/login", options)
  //     .then(res => res.json())
  //     .then(data => {
  //       console.log("log in user returned", data.UserId);
  //       setLoggedIn(typeof data.UserId != 'undefined');
  //     })
  //     .catch (error => console.log("error", error))
  // };

  useEffect(() => {
    // storing input name
    if (!user) {
      localStorage.removeItem("user")
    } else {
      localStorage.setItem("user", JSON.stringify(user));
    }
    
  }, [user]);

  // useEffect(() => {
  //   //console.log("useEffect running");
  //   //console.log("user from useEffect", user);

  //   // });
  //   if (user) getProjects();
  //   if (user) getReceiptInvoices();
  //   if (user) getCustomers();
  //   if (user) getMissingCustomers();
  //   if (user) getSuppliers();
  //   if (user) getMissingSuppliers();
  // }, [ user,setUser ]);

  const getProjects = async () => {
    if (!user) return setProjects([]);
    try {
      // const url = `http://localhost:5000/projects/${user.companyId}`
      const url = `/api/projects/${user.companyId}`
      const res = await fetch(url)
      const data =  await res.json();
      console.log("projects", data.projects)
      setProjects(data.projects)
    } catch (error) {
      console.log(error)
    }
  }

  const getReceiptInvoices = async () => {
    if (!user) return setReceiptInvoices([]);
    try {
      // const url = `http://localhost:5000/receiptinvoices/open/${user.companyId}`
      const url = `/api/receiptinvoices/open/${user.companyId}`
      const res = await fetch(url)
      const data =  await res.json();
      console.log("receiptInvoices", data.receiptInvoices)
      setReceiptInvoices(data.receiptInvoices)
    } catch (error) {
      console.log(error)
    }
  }
  const getCustomers = async () => {
    if (!user) return setCustomers([]);
    try {
      // const url = `http://localhost:5000/customers/${user.companyId}`
      const url = `/api/customers/${user.companyId}`
      const res = await fetch(url)
      const data =  await res.json();
      console.log("customer", data.customers)
      setCustomers(data.customers)
    } catch (error) {
      console.log(error)
    }
  }

  const getMissingCustomers = async () => {
    if (!user) return setMissingCustomers([]);
    try {
      // const url = `http://localhost:5000/customers/missing/customers/${user.companyId}`
      const url = `/api/customers/missing/customers/${user.companyId}`
      const res = await fetch(url)
      const data =  await res.json();
      console.log("missing customers", data.customers)
      setMissingCustomers(data.customers)
    } catch (error) {
      console.log(error)
    }
  }

  const getSuppliers = async () => {
    if (!user) return setSuppliers([]);
    try {
      // const url = `http://localhost:5000/suppliers/${user.companyId}`
      const url = `/api/suppliers/${user.companyId}`
      const res = await fetch(url)
      const data =  await res.json();
      console.log("suppliers", data.suppliers)
      setSuppliers(data.suppliers)
    } catch (error) {
      console.log(error)
    }
  }

  const getMissingSuppliers = async () => {
    if (!user) return setMissingSuppliers([]);
    try {
      // const url = `http://localhost:5000/suppliers/missing/${user.companyId}`
      const url = `/api/suppliers/missing/${user.companyId}`
      const res = await fetch(url)
      const data =  await res.json();
      console.log("missing customers", data.suppliers)
      setMissingSuppliers(data.suppliers)
    } catch (error) {
      console.log(error)
    }
  }

  const getReceipts = async () => {
    if (!user) return setReceipts([]);
    try {
      // const url = `http://localhost:5000/projects/${user.companyId}`
      const url = `api/projects/${user.companyId}`
      const res = await fetch(url)
      const data =  await res.json();
      console.log("projects", data.projects)
      setProjects(data.projects)
    } catch (error) {
      console.log(error)
    }
  }

  const logout = () => {
    window.localStorage.removeItem("user");
    setProjects([]);
    setUser();
  }

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Header user={user} logout={logout}></Header>
        </header>
        <main>
            <div className="content-container">
              {user ? (
                <Routes>
                  <Route path="/projects" element={<Projects invoices={projects} selectedInvoice={selectedInvoice} setSelectedInvoice={setSelectedInvoice} />} />
                  <Route path="/sales" element={<Sales invoices={projects} selectedInvoice={selectedInvoice} setSelectedInvoice={setSelectedInvoice} />} />
                  <Route path="/purchases" element={<Purchases invoices={projects} selectedInvoice={selectedInvoice} setSelectedInvoice={setSelectedInvoice} />} />
                  <Route path="/receipts" element={<ReceiptsAnalysis user={user} receipts={analysis} receiptInvoices={receiptInvoices} setReceiptInvoices={setReceiptInvoices} selectedInvoice={selectedInvoice} setSelectedInvoice={setSelectedInvoice} />} />
                  <Route path="/" element={<ReceiptsAnalysis user={user} receipts={analysis} receiptInvoices={receiptInvoices} setReceiptInvoices={setReceiptInvoices} selectedInvoice={selectedInvoice} setSelectedInvoice={setSelectedInvoice} />} />
                  <Route path="/receipts/:agencyId" element={<Receipts user={user} />} ></Route>

                  <Route path="/payments" element={<PaymentsAnalysis user={user} payments={payanalysis} selectedInvoice={selectedInvoice} setSelectedInvoice={setSelectedInvoice} />} />
                  <Route path="/payments/:channelCode" element={<Payments user={user} />} ></Route>
                  <Route path="/buyers" element={<Customers user={user} />} />
                  <Route path="/owners" element={<Suppliers user={user} />} />
                  <Route path="/search" element={<Search user={user} />} />
              </Routes>
          
          // <InvoiceList invoices={projects} selectedInvoice={selectedInvoice} setSelectedInvoice={setSelectedInvoice}>Invoice List</InvoiceList>
        ) : (
          <Login setUser={setUser}></Login>
        )}
            </div>   
            
            
            
        </main>
        <footer>

        </footer>
      </div>

    </Router>
      
  );
}





export default App;
