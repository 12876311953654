import React, { useEffect , useState } from 'react'
import './PaymentsAnalysis.css';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

export default function PaymentsAnalysis({ user }) {
    const [analysis, setAnalysis] = useState([]);
    const [buyers, setBuyers] = useState([]);
    
    var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      
    //   useEffect(()=>{

    //   },[receiptInvoices])
      
    //   const Analyse = () => {
    //       const buyerArray = [ ... new Set(receiptInvoices.map(ri => ri.agencyName))].sort();
    //       const anal = buyerArray.forEach(buyer => receiptInvoices.map)
    //   }

    useEffect(() => {
      const fetchData = async () => {
        if (!user) return setAnalysis([]);
        try {
          // const url = `http://localhost:5000/paymentsageing/${user.companyId}`
          const url = `/api/paymentsageing/${user.companyId}`
          const res = await fetch(url)
          const data =  await res.json();
          console.log("payments ageing", data.paymentsAnalysis)
          setAnalysis(data.paymentsAnalysis)
        } catch (error) {
          console.log(error)
        }
      };
  
      fetchData();
    }, []);

    // useEffect(()=>{
    //   getAgeing();
    // },[])


    // const getAgeing = async () => {
    //   if (!user) return setAnalysis([]);
    //   try {
    //     const url = `http://localhost:5000/receiptsageing/${user.companyId}`
    //     const res = await fetch(url)
    //     const data =  await res.json();
    //     console.log("receipts ageing", data.receiptsAnalysis)
    //     setAnalysis(data.receiptsAnalysis)
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
    const excelLink = '/api/paymentsageing/excel/'

    return (
    <div className="analysis-container">
        <span className="title">Aged Creditors</span>
        <a href={`${excelLink}${user.companyId}`}> <FontAwesomeIcon icon={faFileExcel} /></a>
        <div className="analysis analysis-header">
            <span className="left-align">Media Owner</span><span className="right-align ccy">Current Month</span><span className="right-align ccy">1 Month</span><span className="right-align ccy">2 Months</span><span className="right-align ccy">3 Months+</span><span className="right-align ccy">Unallocated</span>
        </div>
        {analysis.map(chan => <NavLink to={'/payments/' + chan.ChannelCode}><div key={chan.ChannelCode} className="analysis analysis-row"><span>{chan.ChannelCode}</span><span className="right-align ccy">{formatter.format(chan.Month_0)}</span><span className="right-align ccy">{formatter.format(chan.Month_1)}</span><span className="right-align ccy">{formatter.format(chan.Month_2)}</span><span className="right-align ccy">{formatter.format(chan.Month_3)}</span><span className="right-align ccy">{formatter.format(chan.OpenPayments)}</span></div></NavLink>)}
        <div className="analysis analysis-footer">
            <span>Totals</span><span className="right-align ccy">{formatter.format(analysis.reduce((previousValue, currentValue) => previousValue + currentValue.Month_0,0))}</span><span className="right-align ccy">{formatter.format(analysis.reduce((previousValue, currentValue) => previousValue + currentValue.Month_1,0))}</span><span className="right-align ccy">{formatter.format(analysis.reduce((previousValue, currentValue) => previousValue + currentValue.Month_2,0))}</span><span className="right-align ccy">{formatter.format(analysis.reduce((previousValue, currentValue) => previousValue + currentValue.Month_3,0))}</span><span className="right-align ccy">{formatter.format(analysis.reduce((previousValue, currentValue) => previousValue + currentValue.OpenPayments,0))}</span>
        </div>
    </div>
  )
}