import React from 'react'
import './CommissionEdit.css';
import Commission from './Commission';
import MissingCommission from './MissingCommission';

export default function CommissionEdit({commissions, missingCommissions, setCommissionChanged}) {
    
  
    return (
        <>  
            <div className="commission-matrix">
                <h4 className="commission-edit">Commission Matrix</h4>
                {commissions.map(comm => <Commission commission={comm} setCommissionChanged={setCommissionChanged} key={comm.OrderCategory}></Commission>)}
                {missingCommissions.map(comm => <MissingCommission commission={comm} setCommissionChanged={setCommissionChanged} key={comm.OrderCategory}></MissingCommission>)}
            </div>
            
        </>
    
  )
}
