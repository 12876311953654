import React, { useState, useEffect } from 'react'
import './AllocationBuilderPayment.css';


export default function AllocationBuilderPayment({ selectedInvoices, setSelected, invoices, selectedPayments, payments, clearSelection, setDataChanged }) {
    let scenario = 0;
    var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    const [canAddReceipt, setCanAddReceipt] = useState(false);
    const [allocations, setAllocations] = useState([]);

    const filteredInvoices = invoices.filter(inv => selectedInvoices.includes(inv.invoiceNumber))
    const invoiceTotal = filteredInvoices.reduce((previousValue, currentValue) => previousValue + currentValue.amountOutstanding, 0)
    const filteredPayments = payments.filter(pmt => selectedPayments.includes(pmt.PaymentID));
    const paymentTotal = filteredPayments.reduce((previousValue, currentValue) => previousValue + currentValue.AmountOutstanding, 0)
    const balance = invoiceTotal - paymentTotal;



    //LOGIC....
    //IF we have invoices less than the receipt/payment -> partially allocate the payment against the invoices
    //IF we have invoices MORE than the receipt/payment -> From top down, allocate as maths allows
    //IF we have invoices exactly matching -> allocate against each other
    //IF we want to allocate a number of invoices against a receipt/payment AND create another receipt payment to tidy them up
    
   const submitAllocations = async () => {
       const paymentAllocationData = {
           paymentAllocations: [...allocations]
       }
       console.log(paymentAllocationData)
        try {
            
            // const url = `http://localhost:5000/paymentallocations`;
            const url = `/api/paymentallocations`;
            const res = await fetch(url, {
                method: "post",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(paymentAllocationData)
              })
              
            // if (res.ok) {

            //     //setMissingCustomers(missingCustomers.filter(cust => cust.AgencyId != customer.AgencyId));
            //     // setCustomers([...customers, newItem]);
            // }   
        } catch (error){
            console.log(error)
        }
        
        clear();
        setDataChanged(Date.now());
    }

    const clear = () => {
        setSelected([]);
        clearSelection([]);
    }

    const allocate = () => {
        let tempAllocations = []
        //cannot do anything if we have no invoices to allocate
        if (filteredInvoices.length === 0 || filteredPayments.length === 0) return;

        //Order the two sets smallest to largest and keep taking the smaller away from the larger
        let invoiceBlocks = filteredInvoices.map(invoice => ({InvoiceID: invoice.invoiceNumber, Amount: invoice.amountOutstanding })).sort((a, b) => a.Amount - b.Amount)
        let paymentBlocks = filteredPayments.map(payment => ({PaymentID: payment.PaymentId, Amount: payment.AmountOutstanding})).sort((a, b) => a.Amount - b.Amount)
        
        do {
            if (invoiceBlocks[0].Amount > paymentBlocks[0].Amount) {
                // window.alert(`${invoiceBlocks[0].InvoiceID} ${invoiceBlocks[0].Amount} ${paymentBlocks[0].PaymentID} ${paymentBlocks[0].Amount}`)
                tempAllocations.push({InvoiceID: invoiceBlocks[0].InvoiceID, PaymentID: paymentBlocks[0].PaymentID, Amount: paymentBlocks[0].Amount})
                invoiceBlocks[0].Amount -= paymentBlocks[0].Amount;
                paymentBlocks.splice(0,1);
                
                
            } else if (invoiceBlocks[0].Amount < paymentBlocks[0].Amount){
                tempAllocations.push({InvoiceID: invoiceBlocks[0].InvoiceID, PaymentID: paymentBlocks[0].PaymentID, Amount: invoiceBlocks[0].Amount})
                paymentBlocks[0].Amount -= invoiceBlocks[0].Amount;
                invoiceBlocks.splice(0,1);

            } else if (invoiceBlocks[0].Amount === paymentBlocks[0].Amount) {
                tempAllocations.push({InvoiceID: invoiceBlocks[0].InvoiceID, PaymentID: paymentBlocks[0].PaymentID, Amount: invoiceBlocks[0].Amount})
                invoiceBlocks.splice(0,1);
                paymentBlocks.splice(0,1);
            }
        } while (invoiceBlocks.length > 0 && paymentBlocks.length > 0)
        // console.log(tempAllocations)
        // tempAllocations.forEach(alloc => window.alert(`${alloc.InvoiceID} ${alloc.PaymentID} ${alloc.Amount}`))
        
        setAllocations(tempAllocations)

    }

    useEffect(()=>{
        allocate();
    },[selectedInvoices, selectedPayments])
    
  return (

    <div className="allocation-builder">
        <div>
            {paymentTotal > 0 ? <div className="allocation-block"><div>Payments:</div><div>{formatter.format(paymentTotal)}</div></div>: ""}
            {invoiceTotal > 0 ? <div className="allocation-block"><div>Invoices:</div><div>{formatter.format(invoiceTotal)}</div></div>: ""}
        </div>
        <div>
            {allocations.map(alloc => <div className="allocation-block"><div>{alloc.InvoiceID}</div><div>{formatter.format(alloc.Amount)}</div></div>)}
        </div>
        <div>
            {balance === 0 ? 
                <div className="allocation-block"><div>Balance:</div><div>£0.00</div></div>
                :
                <div className="allocation-block"><div>{balance > 0 ? "Outstanding:" : "Unallocated:"}</div><div>{formatter.format(Math.abs(balance))}</div></div>
            }
        </div>
        <div className="allocation-buttons">
            <div className="allocation-button clear-button" onClick={clear}>Clear</div>
            {paymentTotal === 0 || invoiceTotal === 0 ? "" : <div className="allocation-button allocate-button" onClick={submitAllocations}>Allocate</div>}
        </div>
        
    </div>

   
  )
}
