import React, { useState, useEffect } from 'react'

export default function CustomerName( { user, agencyId } ) {
    const [customerName, setCustomerName] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `/api/customername/${user.companyId}/${agencyId}`
                const res = await fetch(url)
                const data =  await res.json();
                setCustomerName(data.customers[0].AgencyName)
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }, [user, agencyId])

  return (
    <span>{customerName}</span>
  )
}
