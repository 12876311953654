import React, {useState} from 'react'
import ReactDom from 'react-dom'

const MODAL_STYLES = {
	position: 'fixed',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	backgroundColor: '#FFF',
	padding: '50px',
	zIndex: 1000
}

const OVERLAY_STYLES = {
	position: 'fixed',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	backgroundColor: 'rgba(0, 0, 0, .7)',
	zIndex: 1000
}

const CLOSE_STYLES = {
    position: 'fixed',
	right: '0',
    cursor: 'pointer',
    paddingRight: '50px',
    fontSize: '1.5em'
}

export default function Modal({ open, children, onClose }) {
    

	if (!open) return null;
	return ReactDom.createPortal(
		<>
			<div style={OVERLAY_STYLES} />
			<div style={MODAL_STYLES}>   
			<div style={CLOSE_STYLES} onClick={onClose}>&times;</div>
				{children}
			</div>
		</>,
		document.getElementById('portal')
	)
    
}