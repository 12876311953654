import React, {useState} from 'react'
import './CustomerDisplay.css'

export default function CustomerDisplay({customer, missing, agencyname, closeModal, setMissingCustomers, setCustomers, missingCustomers, setDataChanged}) {
    const [formData, setFormData] = useState({
        saleshouseId: customer.SaleshouseId,
        agencyId: customer.AgencyId,
        agencyName: customer.AgencyName,
        contactName: customer.ContactName || "",
        contactEmail: customer.ContactEmail || "",
        address1: customer.Address1 || "",
        address2: customer.Address2 || "",
        address3: customer.Address3 || "",
        address4: customer.Address4 || "",
        postcode: customer.Postcode || ""
    })

    const SaveBuyer = async () => {
        
        const customerData = {
            customers: [{...formData}]
        }
        try {
            
            // const url = `http://localhost:5000/customers/${customer.SaleshouseId}`;
            const url = `/api/customers/${customer.SaleshouseId}`;
            const res = await fetch(url, {
                method: "post",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(customerData)
              })
              
            // if (res.ok) {

            //     //setMissingCustomers(missingCustomers.filter(cust => cust.AgencyId != customer.AgencyId));
            //     // setCustomers([...customers, newItem]);
            // }   
        } catch (error){
            
            console.log(error)
        }
        setDataChanged(Date.now())
        closeModal();
    }

    const UpdateBuyer = async () => {
        const customerData = {
            customers: [{...formData}]
        }
        try {
            
            // const url = `http://localhost:5000/customers/`;
            const url = `/api/customers/`;
            const res = await fetch(url, {
                method: "put",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(customerData)
              })
            
            
        } catch (error){
            console.log(error)
        }
        setDataChanged(Date.now())
        closeModal();
    }

    // const AddCustomer = async () => {
    //     let newClient = { customers: [ {agencyId: agencyId, agencyName: agencyName, contactName: contactName, contactEmail: contactEmail,
    //                     address1: address1, address2: address2, address3: address3, address4: address4, postcode: postcode, saleshouseId: saleshouseId} ]};
    
    
    //     try {
    //         const url = `http://localhost:5000/customers/${customer.saleshouseId}`;
    //         const res = await fetch(url, {
    //             method: "post",
    //             headers: {
    //               'Accept': 'application/json',
    //               'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(newClient)
    //           })
    //         if (res.ok) {
    //             const newItem = await fetch(`http://localhost:5000/customers/${saleshouseId}/${agencyId}`)
    //             const newData = await newItem.json();
    //             setMissingCustomers(missingCustomers.filter(cust => cust.agencyId != customer.agencyId));
    //             setCustomers([...customers, newItem]);
    //         }
            
            
    //     } catch (error){
    //         console.log(error)
    //     }
    
        
        
    //   }

    return (
      <>
        <h4>Buyer Maintenance</h4>
        <div>
            <input className="buyer-maintenance" type="text" value={formData.agencyName} placeholder="Buyer Name" onChange={(e)=>setFormData({...formData, agencyName: e.target.value})} name="buyername" id="buyername" />
            <input className="buyer-maintenance" type="text" value={formData.contactName} placeholder="Contact Name" onChange={(e)=>setFormData({...formData, contactName: e.target.value})} name="contactname" id="contactname" />
            <input className="buyer-maintenance" type="text" value={formData.contactEmail} placeholder="Contact Email" onChange={(e)=>setFormData({...formData, contactEmail: e.target.value})} name="contactemail" id="contactemail" />
            <input className="buyer-maintenance" type="text" value={formData.address1} placeholder="Address1" onChange={(e)=>setFormData({...formData, address1: e.target.value})} name="address1" id="address1" />
            <input className="buyer-maintenance" type="text" value={formData.address2} placeholder="Address2" onChange={(e)=>setFormData({...formData, address2: e.target.value})} name="address2" id="address2" />
            <input className="buyer-maintenance" type="text" value={formData.address3} placeholder="Address3" onChange={(e)=>setFormData({...formData, address3: e.target.value})} name="address3" id="address3" />
            <input className="buyer-maintenance" type="text" value={formData.address4} placeholder="Address4" onChange={(e)=>setFormData({...formData, address4: e.target.value})} name="address4" id="address4" />
            <input className="buyer-maintenance" type="text" value={formData.postcode} placeholder="Postcode" onChange={(e)=>setFormData({...formData, postcode: e.target.value})} name="postcode" id="postcode" />
        </div>
        
        {missing ? <button className="customer-submit-button" onClick={SaveBuyer}>Save Buyer</button> : <button className="customer-submit-button" onClick={UpdateBuyer}>Update Buyer</button>}
      </>

    
  )
}
