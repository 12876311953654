import React, {useEffect, useState} from 'react';
import './Search.css';
// import SearchLedger from './SearchReceiptInvoices';
import SearchReceiptInvoices from './SearchReceiptInvoices'
import SearchReceipt from './SearchReceipt';


export default function Search({ user }) {

  const [search, setSearch] = useState('Invoices')
  


  return (
    <div>
      <h3>Search</h3>
      <div className="search-type-selector">
        <div className={search=='Invoices' ? "search-active-selector search-selector" : "search-inactive-selector search-selector"} onClick={e => setSearch('Invoices')}>Invoices</div>
        <div className={search=='Receipts' ? "search-active-selector search-selector" : "search-inactive-selector search-selector"} onClick={e => setSearch('Receipts')}>Receipts</div>
      
      
      </div>
      
      
      
      {(search == 'Invoices') ? <SearchReceiptInvoices name="Invoices"  user={user}/> : 
      <SearchReceipt name="Receipts"  user={user} />}
    </div>
  )
}
