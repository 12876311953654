import React from 'react';
import './PurchaseList.css';
import Purchase from './Purchase';

export default function PurchaseList({ invoices, selectedInvoice, setSelectedInvoice }) {
  const purchaseList = invoices.map(invoice => (
      <Purchase key={invoice.InvoiceNumber} invoice={invoice} selectedInvoice={selectedInvoice} setSelectedInvoice={setSelectedInvoice}></Purchase>
  ))
  
  return (<div className="list-container"><ul className="purchase-list">{purchaseList}</ul></div>);
}