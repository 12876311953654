import React, {useEffect, useState} from 'react'
import './Customers.css';
import Customer from './Customer';
import MissingCustomer from './MissingCustomer';
import MissingCustomers from './MissingCustomers';

export default function Customers(props) {
  const [customers, setCustomers] = useState();
  const [missingCustomers, setMissingCustomers] = useState();
  const [dataChanged, setDataChanged] = useState();
  console.log("Customers props",props);

  useEffect(() => {

  },[]);

  useEffect(() => {
    //get missing customers
    const fetchData = async () => {
      if (!props.user) return setMissingCustomers([]);
      try {
        // const url = `http://localhost:5000/customers/missing/customers/${props.user.companyId}`
        const url = `/api/customers/missing/customers/${props.user.companyId}`
        const res = await fetch(url)
        const data =  await res.json();
        console.log("missing customers", data.missingCustomers)
        setMissingCustomers(data.customers)
      } catch (error) {
        console.log(error)
      }
    };

    fetchData();
  }, [props.user, dataChanged]);

  useEffect(() => {
    //get customers
    const fetchData = async () => {
      if (!props.user) return setMissingCustomers([]);
      try {
        // const url = `http://localhost:5000/customers/${props.user.companyId}`
        const url = `/api/customers/${props.user.companyId}`
        const res = await fetch(url)
        const data =  await res.json();
        console.log("customers", data.customers)
        setCustomers(data.customers)
      } catch (error) {
        console.log(error)
      }
    };

    fetchData();
  }, [props.user, dataChanged]);

    return (
    <>
        <span className="title">Media Buyers</span>
        <MissingCustomers 
          missingCustomers={missingCustomers} 
          saleshouseId={props.user.companyId} 
          customers={customers} 
          setMissingCustomers={setMissingCustomers}
          setCustomers={setCustomers}
          setDataChanged={setDataChanged} ></MissingCustomers>
        {/* <h3>Missing Media Buyers</h3>
        {missingCustomers.map(cust => <MissingCustomer customer={cust} customers={customers} setCustomers={setCustomers} missingCustomers={missingCustomers} setMissingCustomers={setMissingCustomers} missingCustomer={cust} key={cust.agencyId} saleshouseId={cust.saleshouseId} ></MissingCustomer>)} */}
        <h3>Media Buyers</h3>
        <div className="customers-container">
          {customers != null ? customers.map(cust => <Customer customer={cust} key={cust.AgencyId} setDataChanged={setDataChanged}>{cust.AgencyName} setMissingCustomers={setMissingCustomers} missingCustomers={missingCustomers}</Customer>) : <span>Loading...</span>}
        </div>
        

        
    </>
  )
}
