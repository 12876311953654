import React, { useState, useEffect } from 'react';
import './Payments.css';
import { useParams, Link } from 'react-router-dom';
import AllocationBuilderPayment from './AllocationBuilderPayment';
import PaymentDisplay from './PaymentDisplay';
import Modal from './Modal';
import Payment from './Payment';
import ManualPaymentInvoice from './ManualPaymentInvoice';

export default function Payments({ user }) {
  const params = useParams();
  const [payments, setPayments] = useState([]);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [paymentInvoices, setPaymentInvoices] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isInvOpen, setIsInvOpen] = useState(false);
  const [dataChanged, setDataChanged] = useState();
  const [lastSelectedPayment, setLastSelectedPayment] = useState()
  const [lastSelectedInvoice, setLastSelectedInvoice] = useState()

  var formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const togglePaymentSelection = (paymentId, e) => {
    const invNo = paymentId;
    if (selectedPayments.includes(invNo)) {
      //remove it from the array
      const filteredArray = selectedPayments.filter(item => item != invNo);
      setSelectedPayments(filteredArray);
    } else {
      //add it
      setSelectedPayments( arr => [...arr, paymentId])
    }
  }

  const toggleSelection = (paymentId, e) => {
    e.preventDefault();
    const invNo = paymentId;
    if (selected.includes(invNo)) {
      //remove it from the array
      if (e.shiftKey && lastSelectedInvoice){

        const filteredArray = selected.filter(item => item < Math.min(invNo, lastSelectedInvoice) || item > Math.max(invNo, lastSelectedInvoice));
        setSelected(filteredArray);
      } else {
        const filteredArray = selected.filter(item => item != invNo);
        setSelected(filteredArray);
      }
    } else {
      //add it
      if (e.shiftKey && lastSelectedInvoice) {

        // window.alert(`${e.shiftKey}, ${receiptId}, ${lastSelectedInvoice}`)
        const multiSelect = paymentInvoices.filter(item => item.invoiceNumber >= Math.min(invNo, lastSelectedInvoice) && item.invoiceNumber <= Math.max(invNo, lastSelectedInvoice)).map(item => item.invoiceNumber)
        setSelected( arr => [...arr, ...multiSelect])
      } else {
        setSelected( arr => [...arr, invNo])
      }
    }
    setLastSelectedInvoice(invNo)
    setLastSelectedPayment(null)
  }


  useEffect(()=>{
    const FetchData = async () => {
      if (!user) return setPayments([]);
        try {
          // const url = `http://localhost:5000/payments/${user.companyId}/${params.channelCode}`
          const url = `/api/payments/${user.companyId}/${params.channelCode}`
          const res = await fetch(url)
          const data =  await res.json();
          console.log("receipts", data.payments)
          setPayments(data.payments)
        } catch (error) {
          console.log(error)
        }
    }

    FetchData();
  },[dataChanged])

  useEffect(()=>{
    const FetchData = async () => {
      if (!user) return setPaymentInvoices([]);
        try {
          // const url = `http://localhost:5000/paymentinvoices/${user.companyId}/${params.channelCode}`
          const url = `/api/paymentinvoices/${user.companyId}/${params.channelCode}`
          const res = await fetch(url)
          const data =  await res.json();
          console.log("paymentinvoices", data.paymentinvoices)
          setPaymentInvoices(data.paymentinvoices)
        } catch (error) {
          console.log(error)
        }
    }

    FetchData();
  },[dataChanged])

  return (
    <>
      <div className="payments-container no-select">
      
      <h3><Link to="/payments">&lt;    </Link>Payments for {params.channelCode}</h3>
      <button onClick={() => setIsOpen(true)}>Add a Payment</button><button onClick={() => setIsInvOpen(true)}>Add a Manual invoice</button>
      <div className="with-sidebar"> 
        <div>
        <div className="age-header">Current Month</div>
        <div className="payment-header-titles"><span>Payment Ref</span><span>Amount</span><span>Payment Date</span><span>Amount Outstanding</span></div>
        {payments.filter(payment => payment.MonthsOldGroup == '0').map(payment => <Payment key={payment.PaymentID} payment={payment} selectedPayments={selectedPayments} setSelectedPayments={setSelectedPayments} onclick={(e) => togglePaymentSelection(payment.PaymentID, e)}/>)}
        <div className="invoice-payment-header-titles"><span>Inv Nr</span><span>Outstanding</span><span> Sale Outstanding</span><span>Inv Date</span><span>Gross</span><span>VAT</span><span>Nett</span><span>Order</span></div>
        {paymentInvoices.filter(pi => pi.AgeingGroup == '0').map(cmonth => <div key={cmonth.invoiceNumber} className={`payment-invoice ${selected.includes(cmonth.invoiceNumber) ? "selected" : ""} ${cmonth.SaleAmountOutstanding === 0 ? "released" : ""}`} onClick={(e) => toggleSelection(cmonth.invoiceNumber, e)}><span>{cmonth.invoiceNumber}</span><span>{formatter.format(cmonth.amountOutstanding)}</span><span>{formatter.format(cmonth.SaleAmountOutstanding)}</span><span>{new Date(cmonth.invoiceDate).toLocaleDateString('en-GB')}</span> <span>{formatter.format(cmonth.paymentAmount)}</span><span>{formatter.format(cmonth.vat)}</span><span>{formatter.format(cmonth.nett)}</span> <span>{cmonth.OrderDescription}</span></div>)}
        <div className="age-header">Last Month</div>
        <div className="payment-header-titles"><span>Payment Ref</span><span>Amount</span><span>Payment Date</span><span>Amount Outstanding</span></div>
        {payments.filter(payment => payment.MonthsOldGroup == '1').map(payment => <Payment key={payment.PaymentID} payment={payment} selectedPayments={selectedPayments} setSelectedPayments={setSelectedPayments} onclick={(e) => togglePaymentSelection(payment.PaymentID, e)}/>)}
        <div className="invoice-payment-header-titles"><span>Inv Nr</span><span>Outstanding</span><span> Sale Outstanding</span><span>Inv Date</span><span>Gross</span><span>VAT</span><span>Nett</span><span>Order</span></div>
        {paymentInvoices.filter(pi => pi.AgeingGroup == '1').map(cmonth => <div key={cmonth.invoiceNumber} className={`payment-invoice ${selected.includes(cmonth.invoiceNumber) ? "selected" : ""} ${cmonth.SaleAmountOutstanding === 0 ? "released" : ""}`} onClick={(e) => toggleSelection(cmonth.invoiceNumber, e)}><span>{cmonth.invoiceNumber}</span><span>{formatter.format(cmonth.amountOutstanding)}</span><span>{formatter.format(cmonth.SaleAmountOutstanding)}</span><span>{new Date(cmonth.invoiceDate).toLocaleDateString('en-GB')}</span> <span>{formatter.format(cmonth.paymentAmount)}</span><span>{formatter.format(cmonth.vat)}</span><span>{formatter.format(cmonth.nett)}</span> <span>{cmonth.OrderDescription}</span></div>)}
        <div className="age-header">2 Months Ago</div>
        <div className="payment-header-titles"><span>Payment Ref</span><span>Amount</span><span>Payment Date</span><span>Amount Outstanding</span></div>
        {payments.filter(payment => payment.MonthsOldGroup == '2').map(payment => <Payment key={payment.PaymentID} payment={payment} selectedPayments={selectedPayments} setSelectedPayments={setSelectedPayments} onclick={(e) => togglePaymentSelection(payment.PaymentID, e)}/>)}
        <div className="invoice-payment-header-titles"><span>Inv Nr</span><span>Outstanding</span><span> Sale Outstanding</span><span>Inv Date</span><span>Gross</span><span>VAT</span><span>Nett</span><span>Order</span></div>
        {paymentInvoices.filter(pi => pi.AgeingGroup == '2').map(cmonth => <div key={cmonth.invoiceNumber} className={`payment-invoice ${selected.includes(cmonth.invoiceNumber) ? "selected" : ""} ${cmonth.SaleAmountOutstanding === 0 ? "released" : ""}`} onClick={(e) => toggleSelection(cmonth.invoiceNumber, e)}><span>{cmonth.invoiceNumber}</span><span>{formatter.format(cmonth.amountOutstanding)}</span><span>{formatter.format(cmonth.SaleAmountOutstanding)}</span><span>{new Date(cmonth.invoiceDate).toLocaleDateString('en-GB')}</span> <span>{formatter.format(cmonth.paymentAmount)}</span><span>{formatter.format(cmonth.vat)}</span><span>{formatter.format(cmonth.nett)}</span> <span>{cmonth.OrderDescription}</span></div>)}
        <div className="age-header">3+ Months Ago</div>
        <div className="payment-header-titles"><span>Payment Ref</span><span>Amount</span><span>Payment Date</span><span>Amount Outstanding</span></div>
        {payments.filter(payment => payment.MonthsOldGroup == '3').map(payment => <Payment key={payment.PaymentID} payment={payment} selectedPayments={selectedPayments} setSelectedPayments={setSelectedPayments} onclick={(e) => togglePaymentSelection(payment.PaymentID, e)}/>)}
        <div className="invoice-payment-header-titles"><span>Inv Nr</span><span>Outstanding</span><span> Sale Outstanding</span><span>Inv Date</span><span>Gross</span><span>VAT</span><span>Nett</span><span>Order</span></div>
        {paymentInvoices.filter(pi => pi.AgeingGroup == '3').map(cmonth => <div key={cmonth.invoiceNumber} className={`payment-invoice ${selected.includes(cmonth.invoiceNumber) ? "selected" : ""} ${cmonth.SaleAmountOutstanding === 0 ? "released" : ""}`} onClick={(e) => toggleSelection(cmonth.invoiceNumber, e)}><span>{cmonth.invoiceNumber}</span><span>{formatter.format(cmonth.amountOutstanding)}</span><span>{formatter.format(cmonth.SaleAmountOutstanding)}</span><span>{new Date(cmonth.invoiceDate).toLocaleDateString('en-GB')}</span> <span>{formatter.format(cmonth.paymentAmount)}</span><span>{formatter.format(cmonth.vat)}</span><span>{formatter.format(cmonth.nett)}</span> <span>{cmonth.OrderDescription}</span></div>)}
        </div>
        {selected.length > 0 || selectedPayments.length > 0 ? <div><AllocationBuilderPayment selectedInvoices={selected} setSelected={setSelected} invoices={paymentInvoices} selectedPayments={selectedPayments} payments={payments} clearSelection={setSelectedPayments} setDataChanged={setDataChanged}></AllocationBuilderPayment></div>: ""}
      </div>
      
      
      
    </div>


      <Modal open={isOpen}  onClose={() => setIsOpen(false)}>
        <PaymentDisplay closeModal={() => setIsOpen(false)} saleshouseId={user.companyId} channelCode={params.channelCode} setDataChanged={setDataChanged}></PaymentDisplay>
      </Modal>
      <Modal open={isInvOpen}  onClose={() => setIsInvOpen(false)}>
        <ManualPaymentInvoice closeModal={() => setIsInvOpen(false)} saleshouseId={user.companyId} channelId={params.channelCode} setDataChanged={setDataChanged}></ManualPaymentInvoice>
      </Modal>
    </>
    
  );
}
