import React, {useState, useEffect} from 'react'
import './SearchReceiptInvoices.css'
import SearchResult from './SearchResult';

export default function SearchReceiptInvoices({ name, user }) {
  const baseUrl = `/api/receiptinvoices/${user.companyId}` 
  const xlUrl = `/api/receiptinvoices/export/excel/${user.companyId}` 
  const [criteria, setCriteria] = useState({agency: "", advertiser: "", product: "", invoicenumber: "", order: "", channel: "", invoicedatefrom: "", invoicedateto: ""})
    const [transactions, setTransactions] = useState();

    async function search() {
        console.log("criteria", criteria)
        let searchString = "";
        if (criteria.agency) searchString = `agency=${criteria.agency}`
        if (criteria.advertiser) searchString = (searchString ? searchString + '&' : "" ) + `advertiser=${criteria.advertiser}` 
        if (criteria.product) searchString = (searchString ? searchString + '&' : "" ) + `product=${criteria.product}`
        if (criteria.invoicenumber) searchString = (searchString ? searchString + '&' : "" ) + `invoicenumber=${criteria.invoicenumber}`
        if (criteria.order) searchString = (searchString ? searchString + '&' : "" ) + `order=${criteria.order}`
        if (criteria.channel) searchString = (searchString ? searchString + '&' : "" ) + `channel=${criteria.channel}`
        if (criteria.invoicedatefrom) searchString = (searchString ? searchString + '&' : "" ) + `invoicedatefrom=${criteria.invoicedatefrom}`
        if (criteria.invoicedateto) searchString = (searchString ? searchString + '&' : "" ) + `invoicedateto=${criteria.invoicedateto}`

        const url = baseUrl + '?' + searchString
        const data = await fetch(url)
        const results = await data.json()

        console.log("results", results)
        setTransactions(results.receiptInvoices)
        
    }

    function xlExport() {
      let searchString = "";
        if (criteria.agency) searchString = `agency=${criteria.agency}`
        if (criteria.advertiser) searchString = (searchString ? searchString + '&' : "" ) + `advertiser=${criteria.advertiser}` 
        if (criteria.product) searchString = (searchString ? searchString + '&' : "" ) + `product=${criteria.product}`
        if (criteria.invoicenumber) searchString = (searchString ? searchString + '&' : "" ) + `invoicenumber=${criteria.invoicenumber}`
        if (criteria.order) searchString = (searchString ? searchString + '&' : "" ) + `order=${criteria.order}`
        if (criteria.channel) searchString = (searchString ? searchString + '&' : "" ) + `channel=${criteria.channel}`
        if (criteria.invoicedatefrom) searchString = (searchString ? searchString + '&' : "" ) + `invoicedatefrom=${criteria.invoicedatefrom}`
        if (criteria.invoicedateto) searchString = (searchString ? searchString + '&' : "" ) + `invoicedateto=${criteria.invoicedateto}`

        const url = xlUrl + '?' + searchString
        console.log('fetching excel link')
        // fetch(url)
        window.location.href = url;
        
    }

  return (
    <>
        <div className="search-box">
            {/* SearchCriteria */}
          <div>{name}</div>
          <div className="search-criteria">
            <input type="text" onChange={e => setCriteria({...criteria, agency: e.target.value})} placeholder="Agency Name"></input>
            <input type="text" onChange={e => setCriteria({...criteria, advertiser: e.target.value})} placeholder="Advertiser"></input>
            <input type="text" onChange={e => setCriteria({...criteria, product: e.target.value})} placeholder="Product"></input>
            <input type="number" onChange={e => setCriteria({...criteria, invoicenumber: e.target.value})} placeholder="Invoice Number"></input>
            <input type="text" onChange={e => setCriteria({...criteria, order: e.target.value})} placeholder="Order Description"></input>
            <input type="text" onChange={e => setCriteria({...criteria, channel: e.target.value})} placeholder="Channel"></input>
            <div>
              <label htmlFor="invoicefrom">Invoice Date From:</label>
              <input type="date" id="invoicefrom" onChange={e => setCriteria({...criteria, invoicedatefrom: e.target.value})} placeholder="Invoice Date From"></input>
            </div>
            <div>
              <label htmlFor="invoiceto">Invoice Date To:</label>
              <input type="date" id="invoiceto" onChange={e => setCriteria({...criteria, invoicedateto: e.target.value})} placeholder="Invoice Date To"></input>
            </div>
            
          </div>
        
          <button onClick={search}>Search</button>
          <button onClick={xlExport}>Excel Export</button>
          <div className="search-receipt-invoice-header-titles">
            <span>Invoice</span><span>Amalgamated Invoice</span><span>Amount</span><span>Outstanding</span><span>Invoice Date</span><span>Agency</span><span>Order</span>
          </div>
          {transactions ? [...transactions].map(record => <SearchResult key={record.invoicenumber} record={record} />) : ""}
          

        </div>
        
    </>
    
  )
}
