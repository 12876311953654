import React, { useState, useEffect } from 'react';
import './SalesStatementBatch.css';
import StatementAccount from './StatementAccount';

export default function SalesStatementBatch({ saleshouseId, analysis }) {
    const [selectedAccounts, setSelectedAccounts] = useState([])
    const [runAccounts, setRunAccounts] = useState([])
    const [accountToExport, setAccountToExport] = useState()


    const ExportBatch = () => {
        const accountsArray = []
        for (const account of analysis) {
            if (account.Month_0 || account.Month_1 || account.Month_2 || account.Month_3 || account.OpenReceipts) {
                accountsArray.push(account)

            }
           
          }
        setSelectedAccounts(accountsArray);
        if (accountsArray.length > 0) setAccountToExport(accountsArray[0])
        

    }

    function LoadNextAccount() {
        if (selectedAccounts.length > 0) {
            const theArray = selectedAccounts
            const account = theArray.shift()
            setSelectedAccounts(theArray)
            setAccountToExport(account)
        } else {
            setAccountToExport(null)
        }
    }

    const exportSingleStatement = async (account) => {
        const emptyBody = {
            description: 'request for sales statement'
        }
        
        try {
            const url = `/api/salesstatement/${saleshouseId}/${account.AgencyID}`
            console.log(`calling export single statement. Url is`, url)
            const res = await fetch (url, {
                method: "post",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(emptyBody)
            })
            console.log(`${account.AgencyID} - ${account.AgencyName} exported`)
            LoadNextAccount()

            
            
        } catch (error) {
            console.log(error)
        }
        //setRunAccounts(runAccounts.push(account.AgencyID))
    }

    useEffect(() => {
        if (accountToExport) {
            const account = accountToExport
            setAccountToExport(null)
            exportSingleStatement(account)
            setRunAccounts([...runAccounts, account.AgencyID])
        }
    },[accountToExport])
    // const getStatement = async () => {
        
    //     const emptyBody = {
    //         description: 'request for sales statement'
    //     }
    //     console.log('Requesting sales statement')
    //     try {
            
    //         const url = `/api/salesstatement/${user.companyId}/${params.agencyId}`;
    //         const res = await fetch(url, {
    //             method: "post",
    //             headers: {
    //               'Accept': 'application/json',
    //               'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(emptyBody)
    //           })
              
    //           console.log('Did we get it?')
    //         // if (res.ok) {
    
    //         //     //setMissingCustomers(missingCustomers.filter(cust => cust.AgencyId != customer.AgencyId));
    //         //     // setCustomers([...customers, newItem]);
    //         // }   
    //     } catch (error){
    //         console.log(error)
    //     }
    // }



  return (
    <>
        <div className='batch-title'>Accounts To Export</div>
        <div className='sales-statement-accounts'>
            {analysis.map(account => {
                return (account.Month_0 != 0 || account.Month_1 != 0 || account.Month_2 != 0 || account.Month_3 != 0 || account.OpenReceipts != 0) ?
                
                <StatementAccount account={account} hasRun={runAccounts.includes(account.AgencyID)} exporting={accountToExport==account.AgencyID} key={account.AgencyID} /> 
                : ''
            })}
        </div>
        <button onClick={ExportBatch}>Create Sales Statements</button>
    </>

  )
}
