import React from 'react';
import './Navbar.css';
import { useRouteMatch, Link } from 'react-router-dom';
import NavItem from './NavItem';
import Search from './Search';

export default function Navbar({user}) {
  console.log ("user from navbar",user);
  return (<nav>
    {user != null ? (
      <>
        <ul>
            <NavItem label="Sales Ledger" to="/receipts" activeOnlyWhenExact={true} />
            <NavItem label="Purchase Ledger" to="/payments" activeOnlyWhenExact={true} />
            <NavItem label="Media Buyers" to="/buyers" activeOnlyWhenExact={true} />
            <NavItem label="Media Owners" to="/owners" activeOnlyWhenExact={true} />
            <NavItem label="Search" to="/search" activeOnlyWhenExact={true} />
        </ul>

      
      </>
      
      ) : <div>Sign in</div>}
  </nav>);
}
