import React from 'react';
import './SalesList.css';
import Sale from './Invoice';

export default function SalesList({ invoices, selectedInvoice, setSelectedInvoice }) {
  const invoiceList = invoices.map(invoice => (
      <Sale key={invoice.InvoiceNumber} invoice={invoice} selectedInvoice={selectedInvoice} setSelectedInvoice={setSelectedInvoice}></Sale>
  ))
  
  return (<div className="list-container"><ul className="sale-list">{invoiceList}</ul></div>);
}