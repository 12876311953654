import React, {useState} from 'react'
import './MissingCommission.css';

export default function MissingCommission({commission, setCommissionChanged}) {
    const [formData, setFormData] = useState({
        SaleshouseId: commission.SaleshouseID,
        ChannelCode: commission.ChannelCode,
        OrderCategory: commission.OrderCategory,
        CommissionPCT: 15
    })

    const saveCommission = async () => {
        
        const commissionData = {
            commissions: [{...formData}]
        }
        try {
            
            // const url = `http://localhost:5000/commissionmatrix/${commission.SaleshouseID}/${commission.ChannelCode}`;
            const url = `/api/commissionmatrix/${commission.SaleshouseID}/${commission.ChannelCode}`;
            const res = await fetch(url, {
                method: "post",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(commissionData)
              })
              
            // if (res.ok) {

            //     //setMissingCustomers(missingCustomers.filter(cust => cust.AgencyId != customer.AgencyId));
            //     // setCustomers([...customers, newItem]);
            // }   
        } catch (error){
            window.alert(error)
            console.log(error)
        }
        setCommissionChanged(Date.now())
    }


    return (
      <>
        {/* <label htmlFor="commission">{commission.OrderCategory}</label>
        <input type="number" min="0" max="100" value={formData.CommissionPCT} onChange={(e)=>setFormData({...formData, CommissionPCT: e.target.value})} id="commission" name="commission" />
        <button onClick={saveCommission}>Save</button> */}
        <div className="commission-block">
        <label className="missing-commission-matrix" htmlFor="commission">{commission.OrderCategory}</label>
        <input type="number" min="0" max="100" value={formData.CommissionPCT} onChange={(e)=>setFormData({...formData, CommissionPCT: e.target.value})} id="commission" name="commission"/>
        <button className="matrix-save" onClick={saveCommission}>Save</button>
      </div>
      </>
    
  )
}
