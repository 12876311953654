import React from 'react'
import './MissingSuppliers.css';
import MissingSupplier from './MissingSupplier';

export default function MissingSuppliers({user, missingSuppliers, saleshouseId, suppliers, setMissingSuppliers, setSuppliers, setDataChanged}) {
  
  
  return ((!missingSuppliers || missingSuppliers.length == 0) ? null :
  <>
    <h3>Missing Media Owners</h3>
      {/* {missingCustomers.map(mc => <MissingCustomer key={mc.agencyId} missingCustomer={mc} salehouseId={saleshouseId}></MissingCustomer>)}  */}
      <div className="suppliers-container">
        {missingSuppliers.map(supp => <MissingSupplier user={user} supplier={supp} suppliers={suppliers} setSuppliers={setSuppliers} missingSuppliers={missingSuppliers} setMissingSuppliers={setMissingSuppliers} missingSupplier={supp} key={supp.ChannelCode} saleshouseId={supp.SaleshouseId} setDataChanged={setDataChanged} ></MissingSupplier>)}
      </div>
    
  </>
  )
}
