import React, {useState, useEffect} from 'react'
import './ReceiptDisplay.css'

export default function ReceiptDisplay({saleshouseId, agencyId, closeModal, setDataChanged}) {
    const date = new Date();
    
    const [formData, setFormData] = useState({
        SaleshouseId: saleshouseId,
        AgencyID: agencyId,
        Amount: 0,
        ReceiptDate: date.setHours(0, 0, 0, 0),
        ReceiptRef: ""
    })

    const SaveReceipt = async () => {
        
        const receiptData = {
            receipts: [{...formData}]
        }
        console.log(receiptData)
        try {
            
            // const url = `http://localhost:5000/receipts/${saleshouseId}/${agencyId}`;
            const url = `/api/receipts/${saleshouseId}/${agencyId}`;
            const res = await fetch(url, {
                method: "post",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(receiptData)
              })
              
            // if (res.ok) {

            //     //setMissingCustomers(missingCustomers.filter(cust => cust.AgencyId != customer.AgencyId));
            //     // setCustomers([...customers, newItem]);
            // }   
        } catch (error){
            console.log(error)
        }
        setDataChanged(Date.now())
        closeModal();
    }

  return (
    
    <>
        <h4>Add a receipt</h4>
        <div>
            <input className="receipt-addition" type="number" min="0" step="0.01" value={formData.Amount} placeholder="Amount" onChange={(e)=>setFormData({...formData, Amount: e.target.value})} name="amount" id="amount" />
            <input className="receipt-addition" type="date" value={formData.ReceiptDate} placeholder="ReceiptDate" onChange={(e)=>setFormData({...formData, ReceiptDate: e.target.value})} name="receiptdate" id="receiptdate" />
            <input className="receipt-addition" type="text" value={formData.ReceiptRef} placeholder="Receipt Reference" onChange={(e)=>setFormData({...formData, ReceiptRef: e.target.value})} name="receiptref" id="receiptref" />
        </div>
        <button className="receipt-submit-button" onClick={SaveReceipt}>Save Receipt</button>
    </>
  )
}
