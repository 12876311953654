import React, { useState, useEffect } from 'react'
import './Receipts.css';
import { useParams, Link } from 'react-router-dom';
import AllocationBuilderReceipt from './AllocationBuilderReceipt';
import Modal from './Modal';
import ReceiptDisplay from './ReceiptDisplay';
import ManualReceiptInvoice from './ManualReceiptInvoice';
import Receipt from './Receipt';
import ReceiptInvoice from './ReceiptInvoice';
import CustomerName from './CustomerName';

export default function Receipts({ user }) {
  const [dataChanged, setDataChanged] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [isInvOpen, setIsInvOpen] = useState(false)
  const params = useParams();
  const [receipts, setReceipts] = useState([]);
  const [receiptsLoading, setReceiptsLoading] = useState(true)
  const [selectedReceipts, setSelectedReceipts] = useState([]);
  const [receiptInvoices, setReceiptInvoices] = useState([]);
  const [receiptInvoicesLoading, setReceiptInvoicesLoading] = useState(true)
  const [selected, setSelected] = useState([]);
  const [lastSelectedReceipt, setLastSelectedReceipt] = useState()
  const [lastSelectedInvoice, setLastSelectedInvoice] = useState()

 

  var formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const toggleReceiptSelection = (receiptId, e) => {
    const invNo = receiptId;
    if (selectedReceipts.includes(invNo)) {
      //remove it from the array
      const filteredArray = selectedReceipts.filter(item => item != invNo);
      setSelectedReceipts(filteredArray);
    } else {
      //add it
      setSelectedReceipts( arr => [...arr, receiptId])
    }
    setLastSelectedReceipt(receiptId)
    setLastSelectedInvoice(null)
  }

  const toggleSelection = (receiptId, e) => {
    e.preventDefault();
    const invNo = receiptId;
    if (selected.includes(invNo)) {
      //remove it from the array
      if (e.shiftKey && lastSelectedInvoice){

        const filteredArray = selected.filter(item => item < Math.min(invNo, lastSelectedInvoice) || item > Math.max(invNo, lastSelectedInvoice));
        setSelected(filteredArray);
      } else {
        const filteredArray = selected.filter(item => item != invNo);
        setSelected(filteredArray);
      }
      
    } else {
      //add it
      if (e.shiftKey && lastSelectedInvoice) {

        // window.alert(`${e.shiftKey}, ${receiptId}, ${lastSelectedInvoice}`)
        const multiSelect = receiptInvoices.filter(item => item.invoiceNumber >= Math.min(receiptId, lastSelectedInvoice) && item.invoiceNumber <= Math.max(receiptId, lastSelectedInvoice)).map(item => item.invoiceNumber)
        setSelected( arr => [...arr, ...multiSelect])
      } else {
        setSelected( arr => [...arr, receiptId])
      }
      
    }
    setLastSelectedInvoice(receiptId)
    setLastSelectedReceipt(null)
  }

  const getStatement = async () => {
        
    const emptyBody = {
        description: 'request for sales statement'
    }
    console.log('Requesting sales statement')
    document.body.style.cursor = 'wait'
    try {
        
        const url = `/api/salesstatement/${user.companyId}/${params.agencyId}`;
        const res = await fetch(url, {
            method: "post",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(emptyBody)
          })
          
          console.log('Did we get it?')
        // if (res.ok) {

        //     //setMissingCustomers(missingCustomers.filter(cust => cust.AgencyId != customer.AgencyId));
        //     // setCustomers([...customers, newItem]);
        // }   
    } catch (error){
        console.log(error)
    }
    document.body.style.cursor = 'default'
    
}

  useEffect(()=>{
    const FetchData = async () => {
      if (!user) return setReceipts([]);
        try {
          setReceiptsLoading(true);
          // const url = `http://localhost:5000/receipts/${user.companyId}/${params.agencyId}`
          const url = `/api/receipts/${user.companyId}/${params.agencyId}`
          const res = await fetch(url)
          const data =  await res.json();
          console.log("receipts", data.receipts)
          setReceipts(data.receipts)
        } catch (error) {
          console.log(error)
        }
        setReceiptsLoading(false)
    }

    FetchData();
  },[dataChanged])

  useEffect(()=>{
    const FetchData = async () => {
      if (!user) return setReceiptInvoices([]);
        try {
          setReceiptInvoicesLoading(true)
          // const url = `http://localhost:5000/receiptinvoices/${user.companyId}/${params.agencyId}`
          const url = `/api/receiptinvoices/${user.companyId}/${params.agencyId}`
          const res = await fetch(url)
          const data =  await res.json();
          console.log("invoicereceipts", data.receiptInvoices)
          setReceiptInvoices(data.receiptInvoices)
        } catch (error) {
          console.log(error)
        }
        setReceiptInvoicesLoading(false)
    }

    FetchData();
  },[dataChanged])
  
  return (
    <>
    
    
    <div className="receipts-container no-select">
      
      <h3><Link to="/receipts">&lt;  </Link> Receipts for {params.agencyId} - <CustomerName user={user} agencyId={params.agencyId} /></h3>
      <button onClick={() => setIsOpen(true)}>Add a Receipt</button>
      <button onClick={() => setIsInvOpen(true)}>Add a Manual Invoice</button>
      <button onClick={getStatement}>Create Sales Statement PDF</button>
      <div className="with-sidebar"> 
        <div>
        <div className="age-header">Current Month</div>
        <div className="receipt-header-titles"><span>Receipt Ref</span><span>Amount</span><span>Receipt Date</span><span>Amount Outstanding</span></div>
        {receipts.filter(receipt => receipt.MonthsOldGroup == '0').map(receipt => <Receipt key={receipt.ReceiptID} receipt={receipt} selectedReceipts={selectedReceipts} setSelectedReceipts={setSelectedReceipts} onclick={(e) => toggleReceiptSelection(receipt.ReceiptID, e)}/>)}
        <div className="invoice-receipt-header-titles"><span>Inv Nr</span><span>Amalgamated</span><span>Outstanding</span><span>Inv Date</span><span>Gross</span><span>Order</span></div>
        {/* {receiptInvoicesLoading ? <span>Loading...</span> : receiptInvoices.filter(ri => ri.AgeingGroup == '0').map(cmonth => <div key={cmonth.invoiceNumber} className={selected.includes(cmonth.invoiceNumber) ? "receipt-invoice selected" : "receipt-invoice"} onClick={(e) => toggleSelection(cmonth.invoiceNumber, e)}><span>{cmonth.invoiceNumber}</span><span>{cmonth.amalgamatedInvoice}</span><span>{formatter.format(cmonth.amountOutstanding)}</span><span>{new Date(cmonth.invoiceDate).toLocaleDateString('en-GB')}</span> <span>{formatter.format(cmonth.amount)}</span> <span>{cmonth.OrderDescription}</span></div>)} */}
        {receiptInvoicesLoading ? <span>Loading...</span> : receiptInvoices.filter(ri => ri.AgeingGroup == '0').map(cmonth => <ReceiptInvoice cmonth={cmonth} toggleSelection={toggleSelection} selected={selected} setDataChanged={setDataChanged} />)}
        <div className="age-header">Last Month</div>
        <div className="receipt-header-titles"><span>Receipt Ref</span><span>Amount</span><span>Receipt Date</span><span>Amount Outstanding</span></div>
        {receipts.filter(receipt => receipt.MonthsOldGroup == '1').map(receipt => <Receipt key={receipt.ReceiptID} receipt={receipt} selectedReceipts={selectedReceipts} setSelectedReceipts={setSelectedReceipts} onclick={(e) => toggleReceiptSelection(receipt.ReceiptID, e)}/>)}
        <div className="invoice-receipt-header-titles"><span>Inv Nr</span><span>Amalgamated</span><span>Outstanding</span><span>Inv Date</span><span>Gross</span><span>Order</span></div>
        {/* {receiptInvoicesLoading ? <span>Loading...</span> : receiptInvoices.filter(ri => ri.AgeingGroup == '1').map(cmonth => <div key={cmonth.invoiceNumber} className={selected.includes(cmonth.invoiceNumber) ? "receipt-invoice selected" : "receipt-invoice"} onClick={(e) => toggleSelection(cmonth.invoiceNumber, e)}><span>{cmonth.invoiceNumber}</span><span>{cmonth.amalgamatedInvoice}</span><span>{formatter.format(cmonth.amountOutstanding)}</span><span>{new Date(cmonth.invoiceDate).toLocaleDateString('en-GB')}</span> <span>{formatter.format(cmonth.amount)}</span> <span>{cmonth.OrderDescription}</span></div>)} */}
        
        {receiptInvoicesLoading ? <span>Loading...</span> : receiptInvoices.filter(ri => ri.AgeingGroup == '1').map(cmonth => <ReceiptInvoice cmonth={cmonth} toggleSelection={toggleSelection} selected={selected} setDataChanged={setDataChanged} />)}
        <div className="age-header">2 Months Ago</div>
        <div className="receipt-header-titles"><span>Receipt Ref</span><span>Amount</span><span>Receipt Date</span><span>Amount Outstanding</span></div>
        {receipts.filter(receipt => receipt.MonthsOldGroup == '2').map(receipt => <Receipt key={receipt.ReceiptID} receipt={receipt} selectedReceipts={selectedReceipts} setSelectedReceipts={setSelectedReceipts} onclick={(e) => toggleReceiptSelection(receipt.ReceiptID, e)}/>)}
        <div className="invoice-receipt-header-titles"><span>Inv Nr</span><span>Amalgamated</span><span>Outstanding</span><span>Inv Date</span><span>Gross</span><span>Order</span></div>
        {/* {receiptInvoicesLoading ? <span>Loading...</span> : receiptInvoices.filter(ri => ri.AgeingGroup == '2').map(cmonth => <div key={cmonth.invoiceNumber} className={selected.includes(cmonth.invoiceNumber) ? "receipt-invoice selected" : "receipt-invoice"} onClick={(e) => toggleSelection(cmonth.invoiceNumber, e)}><span>{cmonth.invoiceNumber}</span><span>{cmonth.amalgamatedInvoice}</span><span>{formatter.format(cmonth.amountOutstanding)}</span><span>{new Date(cmonth.invoiceDate).toLocaleDateString('en-GB')}</span> <span>{formatter.format(cmonth.amount)}</span> <span>{cmonth.OrderDescription}</span></div>)} */}
        {receiptInvoicesLoading ? <span>Loading...</span> : receiptInvoices.filter(ri => ri.AgeingGroup == '2').map(cmonth => <ReceiptInvoice cmonth={cmonth} toggleSelection={toggleSelection} selected={selected} setDataChanged={setDataChanged} />)}
        <div className="age-header">3+ Months Ago</div>
        <div className="receipt-header-titles"><span>Receipt Ref</span><span>Amount</span><span>Receipt Date</span><span>Amount Outstanding</span></div>
        {receipts.filter(receipt => receipt.MonthsOldGroup == '3').map(receipt => <Receipt key={receipt.ReceiptID} receipt={receipt} selectedReceipts={selectedReceipts} setSelectedReceipts={setSelectedReceipts} onclick={(e) => toggleReceiptSelection(receipt.ReceiptID, e)}/>)}
        <div className="invoice-receipt-header-titles"><span>Inv Nr</span><span>Amalgamated</span><span>Outstanding</span><span>Inv Date</span><span>Gross</span><span>Order</span></div>
        {/* {receiptInvoicesLoading ? <span>Loading...</span> : receiptInvoices.filter(ri => ri.AgeingGroup == '3').map(cmonth => <div key={cmonth.invoiceNumber} className={selected.includes(cmonth.invoiceNumber) ? "receipt-invoice selected" : "receipt-invoice"} onClick={(e) => toggleSelection(cmonth.invoiceNumber, e)}><span>{cmonth.invoiceNumber}</span><span>{cmonth.amalgamatedInvoice}</span><span>{formatter.format(cmonth.amountOutstanding)}</span><span>{new Date(cmonth.invoiceDate).toLocaleDateString('en-GB')}</span> <span>{formatter.format(cmonth.amount)}</span> <span>{cmonth.OrderDescription}</span></div>)} */}
        {receiptInvoicesLoading ? <span>Loading...</span> : receiptInvoices.filter(ri => ri.AgeingGroup == '3').map(cmonth => <ReceiptInvoice cmonth={cmonth} toggleSelection={toggleSelection} selected={selected} setDataChanged={setDataChanged} />)}
        </div>
        {selected.length > 0 || selectedReceipts.length > 0 ? <div><AllocationBuilderReceipt selectedInvoices={selected} setSelected={setSelected} invoices={receiptInvoices} selectedReceipts={selectedReceipts} receipts={receipts} clearSelection={setSelectedReceipts} setDataChanged={setDataChanged}></AllocationBuilderReceipt></div>: ""}
      </div>
      
      
      
    </div>
    <Modal open={isOpen}  onClose={() => setIsOpen(false)}>
      <ReceiptDisplay closeModal={() => setIsOpen(false)} saleshouseId={user.companyId} agencyId={params.agencyId} setDataChanged={setDataChanged}></ReceiptDisplay>
    </Modal>
    <Modal open={isInvOpen}  onClose={() => setIsInvOpen(false)}>
      <ManualReceiptInvoice closeModal={() => setIsInvOpen(false)} saleshouseId={user.companyId} agencyId={params.agencyId} setDataChanged={setDataChanged}></ManualReceiptInvoice>
    </Modal>
    </>
    
  )
}
