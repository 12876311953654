import React, {useState, useEffect} from 'react'
import './PaymentDisplay.css'

export default function PaymentDisplay({saleshouseId, channelCode, closeModal, setDataChanged}) {
    const date = new Date();
    
    const [formData, setFormData] = useState({
        SaleshouseId: saleshouseId,
        ChannelCode: channelCode,
        Amount: 0,
        PaymentDate: date.setHours(0, 0, 0, 0),
        PaymentRef: ""
    })

    const SavePayment = async () => {
        
        const paymentData = {
            payments: [{...formData}]
        }
        console.log(paymentData)
        try {
            
            // const url = `http://localhost:5000/payments/${saleshouseId}/${channelCode}`;
            const url = `/api/payments/${saleshouseId}/${channelCode}`;
            const res = await fetch(url, {
                method: "post",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(paymentData)
              })
              
            // if (res.ok) {

            //     //setMissingCustomers(missingCustomers.filter(cust => cust.AgencyId != customer.AgencyId));
            //     // setCustomers([...customers, newItem]);
            // }   
        } catch (error){
            console.log(error)
        }
        setDataChanged(Date.now())
        closeModal();
    }

  return (
    
    <>
        <h4>Add a payment</h4>
        <div>
            <input className="payment-addition" type="number" min="0" step="0.01" value={formData.Amount} placeholder="Amount" onChange={(e)=>setFormData({...formData, Amount: e.target.value})} name="amount" id="amount" />
            <input className="payment-addition" type="date" value={formData.PaymentDate} placeholder="PaymentDate" onChange={(e)=>setFormData({...formData, PaymentDate: e.target.value})} name="paymentdate" id="paymentdate" />
            <input className="payment-addition" type="text" value={formData.PaymentRef} placeholder="Payment Reference" onChange={(e)=>setFormData({...formData, PaymentRef: e.target.value})} name="paymentref" id="paymentref" />
        </div>
        <button className="payment-submit-button" onClick={SavePayment}>Save Payment</button>
    </>
  )
}