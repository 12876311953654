import React, {useState} from 'react'
import AllocationList from './AllocationList';
import './ReceiptInvoice.css'

export default function ReceiptInvoice({cmonth, toggleSelection, selected, setDataChanged}) {
  const [allocationsDisplayed, setAllocationsDisplayed] = useState(false)
  const handleAllocClick = (e) => {
    e.stopPropagation();
    
    setAllocationsDisplayed(!allocationsDisplayed);
  }

  var formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  
  return (
    <>
      <div>
        <div key={cmonth.invoiceNumber} className={selected.includes(cmonth.invoiceNumber) ? "receipt-invoice selected" : "receipt-invoice"} onClick={(e) => toggleSelection(cmonth.invoiceNumber, e)}>
          <span>{cmonth.invoiceNumber}</span>
          <span>{cmonth.amalgamatedInvoice}</span>
          <span>{formatter.format(cmonth.amountOutstanding)}</span>
          <span>{new Date(cmonth.invoiceDate).toLocaleDateString('en-GB')}</span>
          <span>{formatter.format(cmonth.amount)}</span>
          <span>{cmonth.OrderDescription}</span>
          {cmonth.Allocations >=1 && <button className='alloc-button' onClick={handleAllocClick}>{allocationsDisplayed ? 'hide' : 'edit allocations'}</button>}
          </div>
          <div>{allocationsDisplayed && <AllocationList invoiceId={cmonth.invoiceNumber} receiptId={null} setDataChanged={setDataChanged} />}</div>
      </div>
    </>
  )
}
