import React, {useState, useEffect} from 'react'
import './Suppliers.css';
import MissingSuppliers from './MissingSuppliers';
import Supplier from './Supplier';

export default function Suppliers(props) {
  const [suppliers, setSuppliers] = useState();
  const [missingSuppliers, setMissingSuppliers] = useState();
  const [dataChanged, setDataChanged] = useState();
  

  useEffect(() => {
    
  },[]);

  useEffect(() => {
    //get missing suppliers
    const fetchData = async () => {
      if (!props.user) return setMissingSuppliers([]);
      try {
        // const url = `http://localhost:5000/suppliers/missing/suppliers/${props.user.companyId}`
        const url = `/api/suppliers/missing/suppliers/${props.user.companyId}`
        const res = await fetch(url)
        const data =  await res.json();
        console.log("missing suppliers", data.suppliers)
        setMissingSuppliers(data.suppliers)
      } catch (error) {
        console.log(error)
      }
    };

    fetchData();
  }, [props.user, dataChanged]);

  useEffect(() => {
    //get suppliers
    const fetchData = async () => {
      if (!props.user) return setMissingSuppliers([]);
      try {
        // const url = `http://localhost:5000/suppliers/${props.user.companyId}`
        const url = `/api/suppliers/${props.user.companyId}`
        const res = await fetch(url)
        const data =  await res.json();
        console.log("suppliers", data.suppliers)
        setSuppliers(data.suppliers)
      } catch (error) {
        console.log(error)
      }
    };

    fetchData();
  }, [props.user, dataChanged]);
  
  //if (!props.user) return null;
  return (
    <>
    <span className="title">Media Owners</span>
    <MissingSuppliers 
      missingSuppliers={missingSuppliers} 
      saleshouseId={props.user.companyId} 
      suppliers={suppliers} 
      setMissingSuppliers={setMissingSuppliers}
      setSuppliers={setSuppliers}
      setDataChanged={setDataChanged}
      user={props.user}></MissingSuppliers>
    {/* <h3>Missing Media Buyers</h3>
    {missingCustomers.map(cust => <MissingCustomer customer={cust} customers={customers} setCustomers={setCustomers} missingCustomers={missingCustomers} setMissingCustomers={setMissingCustomers} missingCustomer={cust} key={cust.agencyId} saleshouseId={cust.saleshouseId} ></MissingCustomer>)} */}
    <h3>Media Owners</h3>
    <div className="suppliers-container">
      {suppliers != null ? suppliers.map(supp => <Supplier supplier={supp} key={supp.ChannelCode} user={props.user} setDataChanged={setDataChanged} channelName={supp.ChannelName} setMissingSuppliers={setMissingSuppliers} missingSuppliers={missingSuppliers}></Supplier>) : <span>Loading...</span>}
    </div>
    

    
</>
    
  )
}
