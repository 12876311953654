import React, { useState, useEffect } from 'react'
import './AllocationBuilderReceipt.css';


export default function AllocationBuilderReceipt({ selectedInvoices, setSelected, invoices, selectedReceipts, receipts, clearSelection, setDataChanged }) {
    let scenario = 0;
    var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    const [canAddReceipt, setCanAddReceipt] = useState(false);
    const [allocations, setAllocations] = useState([]);

    const filteredInvoices = invoices.filter(inv => selectedInvoices.includes(inv.invoiceNumber))
    const invoiceTotal = filteredInvoices.reduce((previousValue, currentValue) => previousValue + currentValue.amountOutstanding, 0)
    const filteredReceipts = receipts.filter(rcpt => selectedReceipts.includes(rcpt.ReceiptID));
    const receiptTotal = filteredReceipts.reduce((previousValue, currentValue) => previousValue + currentValue.AmountOutstanding, 0)
    const balance = invoiceTotal - receiptTotal;



    //LOGIC....
    //IF we have invoices less than the receipt/payment -> partially allocate the payment against the invoices
    //IF we have invoices MORE than the receipt/payment -> From top down, allocate as maths allows
    //IF we have invoices exactly matching -> allocate against each other
    //IF we want to allocate a number of invoices against a receipt/payment AND create another receipt payment to tidy them up
    
   const submitAllocations = async () => {
       const receiptAllocationData = {
           receiptAllocations: [...allocations]
       }
       console.log(receiptAllocationData)
        try {
            
            // const url = `http://localhost:5000/receiptallocations`;
            const url = `/api/receiptallocations`;
            const res = await fetch(url, {
                method: "post",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(receiptAllocationData)
              })
              
            // if (res.ok) {

            //     //setMissingCustomers(missingCustomers.filter(cust => cust.AgencyId != customer.AgencyId));
            //     // setCustomers([...customers, newItem]);
            // }   
        } catch (error){
            console.log(error)
        }
        
        clear();
        setDataChanged(Date.now());
    }

    const clear = () => {
        setSelected([]);
        clearSelection([]);
    }

    const allocate = () => {
        let tempAllocations = []
        //cannot do anything if we have no invoices to allocate
        if (filteredInvoices.length === 0 || filteredReceipts.length === 0) return;

        //Order the two sets smallest to largest and keep taking the smaller away from the larger
        let invoiceBlocks = filteredInvoices.map(invoice => ({InvoiceID: invoice.invoiceNumber, Amount: invoice.amountOutstanding })).sort((a, b) => a.Amount - b.Amount)
        let receiptBlocks = filteredReceipts.map(receipt => ({ReceiptID: receipt.ReceiptID, Amount: receipt.AmountOutstanding})).sort((a, b) => a.Amount - b.Amount)
        
        do {
            if (invoiceBlocks[0].Amount > receiptBlocks[0].Amount) {
                // window.alert(`${invoiceBlocks[0].InvoiceID} ${invoiceBlocks[0].Amount} ${receiptBlocks[0].ReceiptID} ${receiptBlocks[0].Amount}`)
                tempAllocations.push({InvoiceID: invoiceBlocks[0].InvoiceID, ReceiptID: receiptBlocks[0].ReceiptID, Amount: receiptBlocks[0].Amount})
                invoiceBlocks[0].Amount -= receiptBlocks[0].Amount;
                receiptBlocks.splice(0,1);
                
                
            } else if (invoiceBlocks[0].Amount < receiptBlocks[0].Amount){
                tempAllocations.push({InvoiceID: invoiceBlocks[0].InvoiceID, ReceiptID: receiptBlocks[0].ReceiptID, Amount: invoiceBlocks[0].Amount})
                receiptBlocks[0].Amount -= invoiceBlocks[0].Amount;
                invoiceBlocks.splice(0,1);

            } else if (invoiceBlocks[0].Amount === receiptBlocks[0].Amount) {
                tempAllocations.push({InvoiceID: invoiceBlocks[0].InvoiceID, ReceiptID: receiptBlocks[0].ReceiptID, Amount: invoiceBlocks[0].Amount})
                invoiceBlocks.splice(0,1);
                receiptBlocks.splice(0,1);
            }
        } while (invoiceBlocks.length > 0 && receiptBlocks.length > 0)
        // console.log(tempAllocations)
        // tempAllocations.forEach(alloc => window.alert(`${alloc.InvoiceID} ${alloc.ReceiptID} ${alloc.Amount}`))
        
        setAllocations(tempAllocations)

    }

    useEffect(()=>{
        allocate();
    },[selectedInvoices, selectedReceipts])
    
  return (

    <div className="allocation-builder">
        <div>
            {receiptTotal > 0 ? <div className="allocation-block"><div>Receipts:</div><div>{formatter.format(receiptTotal)}</div></div>: ""}
            {invoiceTotal > 0 ? <div className="allocation-block"><div>Invoices:</div><div>{formatter.format(invoiceTotal)}</div></div>: ""}
        </div>
        <div>
            {allocations.map(alloc => <div className="allocation-block"><div>{alloc.InvoiceID}</div><div>{formatter.format(alloc.Amount)}</div></div>)}
        </div>
        <div>
            {balance === 0 ? 
                <div className="allocation-block"><div>Balance:</div><div>£0.00</div></div>
                :
                <div className="allocation-block"><div>{balance > 0 ? "Outstanding:" : "Unallocated:"}</div><div>{formatter.format(Math.abs(balance))}</div></div>
            }
        </div>
        <div className="allocation-buttons">
            <div className="allocation-button clear-button" onClick={clear}>Clear</div>
            {receiptTotal === 0 || invoiceTotal === 0 ? "" : <div className="allocation-button allocate-button" onClick={submitAllocations}>Allocate</div>}
        </div>
        
    </div>

    // <div className="allocation-builder">
    //     <ul className="allocation-item-list">
    //         {allocations.map(alloc => <li>Invoice {alloc.InvoiceID} + Receipt {alloc.ReceiptID} {formatter.format(alloc.Amount)}</li>)}
    //     </ul>
    //     <ul className="allocation-item-list">
    //     {filteredReceipts.map(receipt => <li className="allocation-receipt-item"><div className='invoice-allocation' key={receipt.ReceiptID}><div className="allocation-invoice-number">{receipt.ReceiptRef}</div> <div className="allocation-invoice-amount">{formatter.format(receipt.AmountOutstanding)}</div></div></li>)}
    //     </ul>
    //     <ul className="allocation-item-list">
    //     {filteredInvoices.map(invoice => <li className="allocation-receipt-item"><div className='invoice-allocation' key={invoice.invoiceNumber}><div className="allocation-invoice-number">{invoice.invoiceNumber}</div> <div className="allocation-invoice-amount">{formatter.format(invoice.amountOutstanding)}</div></div></li>)}
    //     </ul>
        
    //     <div className="allocation-total invoice-allocation"><span>Received: </span><span className="allocation-total invoice-allocation">{formatter.format(receiptTotal)}</span></div>
    //     <div className="allocation-total invoice-allocation"><span>Allocate Against: </span><span className="allocation-total invoice-allocation">{formatter.format(invoiceTotal)}</span></div>
    //     <div className="allocation-total invoice-allocation"><span>Balance: </span><span className="allocation-total invoice-allocation">{formatter.format(filteredReceipts.reduce((previousValue, currentValue) => previousValue + currentValue.AmountOutstanding,0) - filteredInvoices.reduce((previousValue, currentValue) => previousValue + currentValue.amountOutstanding,0))}</span></div>
    //     <button onClick={clear}>Clear</button>
    //     <button onClick={submitAllocations}>Allocate</button>
    // </div>
  )
}