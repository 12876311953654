import React from 'react'
import './Payment.css';

export default function Payment({payment, selectedPayments, setSelectedPayments, onclick}) {
    
    var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

  return (
      <>
        <div className={selectedPayments.includes(payment.PaymentID) ? "selected payment" : "payment"} onClick={onclick}>
            <div>{payment.PaymentRef}</div>
            <div>{formatter.format(payment.Amount)}</div>
            <div>{new Date(payment.PaymentDate).toLocaleDateString('en-GB')}</div>
            {/* <div>{receipt.ReceiptDate}</div> */}
            
            <div>{formatter.format(payment.AmountOutstanding)}</div>
        </div>
        {/* {receiptInvoices.filter(ri => ri.AgeingGroup == '0').map(cmonth => <div key={cmonth.invoiceNumber} className={selected.includes(cmonth.invoiceNumber) ? "receipt selected" : "receipt"} onClick={(e) => toggleSelection(cmonth.invoiceNumber, e)}><span>{cmonth.invoiceNumber}</span><span>{formatter.format(cmonth.amountOutstanding)}</span><span>{new Date(cmonth.invoiceDate).toLocaleDateString('en-GB')}</span> <span>{formatter.format(cmonth.amount)}</span><span>{formatter.format(cmonth.vat)}</span><span>{formatter.format(cmonth.nett)}</span> <span>{cmonth.OrderDescription}</span></div>)} */}
      </>
    
  )
}
