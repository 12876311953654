import React from 'react'
import { NavLink } from 'react-router-dom';
import './NavItem.css';

export default function NavItem({ label, to }) {

    return (
        <li>
            <NavLink className={navData => navData.isActive ? "active nav-header" : 'nav-header'} to={to} >{label}</NavLink>
        </li>
    )
}

