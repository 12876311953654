import React, {useState} from 'react'
import './Commission.css';

export default function Commission({commission, setCommissionChanged}) {
    const [formData, setFormData] = useState({
        SaleshouseID: commission.SaleshouseID,
        ChannelCode: commission.ChannelCode,
        OrderCategory: commission.OrderCategory,
        CommissionPCT: commission.CommissionPCT
    })

    const updateCommission = async () => {
        const commissionData = {
            commissions: [{...formData}]
        }
        try {
            
            // const url = `http://localhost:5000/commissionmatrix/`;
            const url = `/api/commissionmatrix/`;
            const res = await fetch(url, {
                method: "put",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(commissionData)
              })
            
            
        } catch (error){
            console.log(error)
        }
        setCommissionChanged(Date.now())
    }

    return (
      <div className="commission-block">
        <div>
          <label className="talllabel" htmlFor={`commission-${commission.OrderCategory}`} >{commission.OrderCategory}</label>
        </div>
        <div className="simplecontainer">
          <input type="number" min="0" max="100" value={formData.CommissionPCT} onChange={(e)=>setFormData({...formData, CommissionPCT: e.target.value})} id={`commission-${commission.OrderCategory}`} name="commission"/>
          <button className="matrix-save" onClick={updateCommission}>Update</button>
        </div>
        
      </div>
    
  )
}
