import React, {useState} from 'react'
import './MissingCustomer.css';
import Modal from './Modal';
import CustomerDisplay from './CustomerDisplay';

export default function MissingCustomer({customer, saleshouseId, selected, setCustomers, customers, missingCustomer, missingCustomers, setMissingCustomers, setDataChanged }) {
    const [agencyId, setAgencyId] = useState(missingCustomer.agencyId)
    const [agencyName, setAgencyName] = useState(missingCustomer.AgencyName)
    const [contactName, setContactName] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [address1, setAddress1] = useState(missingCustomer.Address1);
    const [address2, setAddress2] = useState(missingCustomer.Address2);
    const [address3, setAddress3] = useState(missingCustomer.Address3);
    const [address4, setAddress4] = useState("");
    const [postcode, setPostcode] = useState(missingCustomer.Postcode);
    const [isOpen, setIsOpen] = useState(false);
    

  const AddCustomer = async () => {
    let newClient = { customers: [ {agencyId: agencyId, agencyName: agencyName, contactName: contactName, contactEmail: contactEmail,
                    address1: address1, address2: address2, address3: address3, address4: address4, postcode: postcode, saleshouseId: saleshouseId} ]};


    try {
        // const url = `http://localhost:5000/customers/${customer.saleshouseId}`;
        const url = `/api/customers/${customer.saleshouseId}`;
        const res = await fetch(url, {
            method: "post",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(newClient)
          })
        if (res.ok) {
            // const newItem = await fetch(`http://localhost:5000/customers/${saleshouseId}/${agencyId}`)
            const newItem = await fetch(`/api/customers/${saleshouseId}/${agencyId}`)
            const newData = await newItem.json();
            setMissingCustomers(missingCustomers.filter(cust => cust.agencyId != customer.agencyId));
            setCustomers([...customers, newItem]);
        }
        
        
    } catch (error){
        console.log(error)
    }

    
    
  }
  const addressFields = [];
  if (customer.Address1) addressFields.push(customer.Address1);
  if (customer.Address2) addressFields.push(customer.Address2);
  if (customer.Address3) addressFields.push(customer.Address3);
  if (customer.Address4) addressFields.push(customer.Address4);
  let address = addressFields.join(', ');
  if (customer.Postcode) address = address + ' '  + customer.Postcode;

    
  return (
    // <div className="customer missing">
    //     <input className="customer-info" value={customer.AgencyName} type="text" placeholder={customer.AgencyName} onChange={e => setAgencyName(e.target.value)}></input>
    //     <input className="customer-info" type="text" placeholder="contact name" onChange={e => setContactName(e.target.value)}></input>
    //     <input className="customer-info" type="text" placeholder="contact email" onChange={e => setContactEmail(e.target.value)}></input>
    //     <input className="customer-info" value={customer.Address1} type="text" placeholder={customer.Address1} onChange={e => setAddress1(e.target.value)}></input>
    //     <input className="customer-info" value={customer.Address2} type="text" placeholder={customer.Address2} onChange={e => setAddress2(e.target.value)}></input>
    //     <input className="customer-info" value={customer.Address3} type="text" placeholder={customer.Address3} onChange={e => setAddress3(e.target.value)}></input>
    //     <input className="customer-info" type="text" placeholder="" onChange={e => setAddress4(e.target.value)}></input>
    //     <input className="customer-info" value={customer.Postcode} type="text" placeholder={customer.Postcode} onChange={e => setPostcode(e.target.value)}></input>
    //     <button className="customer-add" onClick={e => AddCustomer()}>Add {customer.agencyId}</button>
        
    // </div>
    <>
      <div className="customer missing customer-container" onClick={() => setIsOpen(true)}>
        <div className="customer-info agency-id">{customer.AgencyId}</div>
        <div className="customer-info agency-name">{customer.AgencyName}</div>
        {/* <div className="customer-info contact-name">{customer.ContactName}</div>
        <div className="customer-info contact-email">{customer.ContactEmail}</div> */}
        <div className="customer-info address">{address}</div>
      </div>
      <Modal open={isOpen}  onClose={() => setIsOpen(false)} >
        <CustomerDisplay customer={customer} missing={true} agencyname={customer.AgencyName} closeModal={() => setIsOpen(false)} setMissingCustomers={setMissingCustomers} setDataChanged={setDataChanged}></CustomerDisplay>
      </Modal>
    </>
    
  )
}
