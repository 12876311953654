import React, {useState, useEffect} from 'react'
import CommissionEdit from './CommissionEdit';
import './SupplierDisplay.css';


export default function SupplierDisplay({user, supplier, missing, channelname, closeModal, setMissingSuppliers, setSuppliers, missingSuppliers, setDataChanged}) {
    const [missingCommissions, setMissingCommissions] = useState([]);
    const [commissions, setCommissions] = useState([]);
    const [commissionChanged, setCommissionChanged] = useState();
    
    const [formData, setFormData] = useState({
        SaleshouseId: user.companyId,
        ChannelCode: supplier.ChannelCode,
        ChannelName: supplier.ChannelName,
        ContactName: supplier.ContactName || "",
        ContactEmail: supplier.ContactEmail || "",
        Address1: supplier.Address1 || "",
        Address2: supplier.Address2 || "",
        Address3: supplier.Address3 || "",
        Address4: supplier.Address4 || "",
        Postcode: supplier.Postcode || ""
    })

    useEffect(() => {
        //get missing commission
        
        const fetchData = async () => {
            
          if (!user) return setMissingCommissions([]);
          
          try {
            // const url = `http://localhost:5000/commissionmatrix/missing/${user.companyId}/${supplier.ChannelCode}`
            const url = `/api/commissionmatrix/missing/${user.companyId}/${supplier.ChannelCode}`
            const res = await fetch(url)
            const data =  await res.json();
            console.log("missing commissions", data.commissions)
            setMissingCommissions(data.commissions)
          } catch (error) {
            console.log(error)
          }
        };
    
        fetchData();
      }, [user, commissionChanged]);

      useEffect(() => {
        //get commission
        const fetchData = async () => {
          //if (!user) return setCommissions([]);
          try {
            // const url = `http://localhost:5000/commissionmatrix/${user.companyId}/${supplier.ChannelCode}`
            const url = `/api/commissionmatrix/${user.companyId}/${supplier.ChannelCode}`
            const res = await fetch(url)
            const data =  await res.json();
            console.log("commissions", data.commissions)
            setCommissions(data.commissions)
          } catch (error) {
            console.log(error)
          }
        };
    
        fetchData();
      }, [user, commissionChanged]);

    const SaveSupplier = async () => {
        
        const supplierData = {
            suppliers: [{...formData}]
        }
        try {
            
            // const url = `http://localhost:5000/suppliers/${supplier.SaleshouseID}`;
            const url = `/api/suppliers/${supplier.SaleshouseID}`;
            const res = await fetch(url, {
                method: "post",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(supplierData)
              })
              
            // if (res.ok) {

            //     //setMissingCustomers(missingCustomers.filter(cust => cust.AgencyId != customer.AgencyId));
            //     // setCustomers([...customers, newItem]);
            // }   
        } catch (error){
            
            console.log(error)
        }
        setDataChanged(Date.now())
        closeModal();
    }

    const UpdateSupplier = async () => {
        const supplierData = {
            suppliers: [{...formData}]
        }
        try {
            
            // const url = `http://localhost:5000/suppliers/`;
            const url = `/api/suppliers/`;
            const res = await fetch(url, {
                method: "put",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(supplierData)
              })
            
            
        } catch (error){
            console.log(error)
        }
        setDataChanged(Date.now())
        closeModal();
    }
  
    return (
        <>
        <h4>Owner Maintenance</h4>
        <div>
            <input className="owner-maintenance" type="text" value={formData.ChannelName} placeholder="Owner Name" onChange={(e)=>setFormData({...formData, ChannelName: e.target.value})} name="ownername" id="ownername" />
            <input className="owner-maintenance" type="text" value={formData.ContactName} placeholder="Contact Name" onChange={(e)=>setFormData({...formData, ContactName: e.target.value})} name="contactname" id="contactname" />
            <input className="owner-maintenance" type="text" value={formData.ContactEmail} placeholder="Contact Email" onChange={(e)=>setFormData({...formData, ContactEmail: e.target.value})} name="contactemail" id="contactemail" />
            <input className="owner-maintenance" type="text" value={formData.Address1} placeholder="Address1" onChange={(e)=>setFormData({...formData, Address1: e.target.value})} name="address1" id="address1" />
            <input className="owner-maintenance" type="text" value={formData.Address2} placeholder="Address2" onChange={(e)=>setFormData({...formData, Address2: e.target.value})} name="address2" id="address2" />
            <input className="owner-maintenance" type="text" value={formData.Address3} placeholder="Address3" onChange={(e)=>setFormData({...formData, Address3: e.target.value})} name="address3" id="address3" />
            <input className="owner-maintenance" type="text" value={formData.Address4} placeholder="Address4" onChange={(e)=>setFormData({...formData, Address4: e.target.value})} name="address4" id="address4" />
            <input className="owner-maintenance" type="text" value={formData.Postcode} placeholder="Postcode" onChange={(e)=>setFormData({...formData, Postcode: e.target.value})} name="postcode" id="postcode" />
        </div>
        
        {missing ? <button className="supplier-submit-button" onClick={SaveSupplier}>Save Owner</button> : <button className="supplier-submit-button" onClick={UpdateSupplier}>Update Owner</button>}
        {!missing ? 
            <CommissionEdit commissions={commissions} missingCommissions={missingCommissions} setCommissionChanged={setCommissionChanged}></CommissionEdit>
        :
            ""
        }
      </>
  )
}
