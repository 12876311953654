import React, {useState} from 'react'
import './Supplier.css';
import Modal from './Modal';
import SupplierDisplay from './SupplierDisplay';

export default function Supplier({supplier, setDataChanged, user}) {
  const [isOpen, setIsOpen] = useState(false);

  const addressFields = [];
  if (supplier.Address1) addressFields.push(supplier.Address1);
  if (supplier.Address2) addressFields.push(supplier.Address2);
  if (supplier.Address3) addressFields.push(supplier.Address3);
  if (supplier.Address4) addressFields.push(supplier.Address4);
  let address = addressFields.join(', ');
  if (supplier.Postcode) address = address + ' '  + supplier.Postcode;

  return (
    <>
    <div className="supplier missing supplier-container" onClick={() => setIsOpen(true)}>
      
      <div className="supplier-info channel-code">{supplier.ChannelCode}</div>
      <div className="supplier-info agency-name">{supplier.ChannelName}</div>
      {/* <div className="supplier-info contact-name">{supplier.ContactName}</div>
      <div className="supplier-info contact-email">{supplier.ContactEmail}</div> */}
      <div className="supplier-info address">{address}</div>
      {supplier.MissingCommissions > 0 ? 
      (<div className="supplier-info missing-commission">{supplier.MissingCommissions}</div>)
      :
      <div className="supplier-info missing-commission"></div>
      }
      
    
    </div>
    <Modal open={isOpen}  onClose={() => setIsOpen(false)} >
        <SupplierDisplay user={user} supplier={supplier} missing={false} ChannelName={supplier.ChannelName} closeModal={() => setIsOpen(false)} setDataChanged={setDataChanged}></SupplierDisplay>
    </Modal>
  </>
  )
}
