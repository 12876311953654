import React, {useEffect} from 'react';
import './Purchase.css'; 

export default function Purchase({invoice, selectedInvoice, setSelectedInvoice}) {
    var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      
    const startDate = new Date(invoice.CampaignStartDate);
    const endDate = new Date(invoice.CampaignEndDate);
    const invoiceDate = new Date(invoice.InvoiceDate);
    
    const selectThis = () => {
        setSelectedInvoice(invoice.InvoiceNumber);
    }
    const markPaid = async () => {
        // const url = `http://localhost:5000/projects/purchasepaid/${invoice.InvoiceNumber}`
        const url = `/api/projects/purchasepaid/${invoice.InvoiceNumber}`
        const options = {
            method: 'POST'
        }


    
        try {
          const res = await fetch(url, options)
          const data =  await res.json();
          console.log("project returned?", data.project)
          //do something to update the projects array!!!
          invoice.PurchaseInvoicePaid = 1;
        } catch (error) {
          console.log(error)
        }
    }

    const isSelected = (selectedInvoice === invoice.InvoiceNumber);
    const classes = isSelected ? "invoice selected" : "invoice";  
    const actionPanel = isSelected && invoice.PurchaseInvoiceReleased ? (
        <div className="action-panel"><button className="mark-paid" onClick={markPaid}>Mark as Paid</button></div>
        ) : ""

        useEffect(() => {
            // storing input name
            
          }, [invoice]);
    
    
    return (
    <li className={classes} onClick={selectThis}>
        <div className="invoice-number">Invoice: {invoice.invoiceNumber}</div>
        <div className="grid-middle">
            <div className="order-description">{invoice.OrderDescription}</div>
            <div className="agency">Agency: {invoice.InvoiceAgencyId}</div>
            
            {/* <div className="contact">{invoice.ContactPerson}</div> */}
            <div className="order">Order: {invoice.OrderID}</div>
            <div className="transaction-type">Trans: {invoice.TransactionType}</div>
            <div className="date-field">Campaign {startDate.toLocaleDateString("en-GB")}-{endDate.toLocaleDateString("en-GB")}</div>
            <div className="date-field">InvoiceDate: {invoiceDate.toLocaleDateString("en-GB")}</div>
            <div className="order-category">{invoice.OrderCategory}</div>
            <div className="advertiser">{invoice.Advertiser}</div>
            <div className="salesperson">{invoice.SalesPerson}</div>
            <div className="product">{invoice.Product}</div>
            <div className="channel">{invoice.Channel}</div>
            <div className="money">Nett: {formatter.format(invoice.Nett)}</div>
            
            {/* <div className="credited-by">Cr By: {invoice.CreditedBy}</div> */}
            <div className="product-only">{invoice.ProductOnly}</div>
            {/* <div className="advertiser-for">Adv for: {invoice.AdvertiserFor}</div> */}
            <div className="money">Sales:{formatter.format(invoice.SalesInvoiceAmount)}</div>
            <div className="commission">Comm: {formatter.format(invoice.AgencyCommissionValue)}</div>
            <div className="flag">{invoice.SalesInvoicePaid}</div>
            <div className="flag">{invoice.PurchaseInvoiceReleased}</div>
            <div className="flag">{invoice.PurchaseInvoicePaid}</div>
            {invoice.PurchaseInvoicePaid ? <div>Paid</div> : invoice.PurchaseInvoiceReleased ? <div>Released for Payment</div> : <div>Not released</div>}
        </div>
        <div className="money sales">Prch:{formatter.format(invoice.PurchaseInvoiceAmount)}</div>
        {actionPanel}
    </li>
    
    );
}