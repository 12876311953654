import React, { useState } from 'react';
import './Login.css';


export default function Login({ setUser }) {

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("")



    // const login = () => {
    //     const filteredUser = users.filter(user => user.username === userName && user.password === password);
    //     if (filteredUser.length === 1) {
    //         setErrorMessage("");
    //         window.localStorage.setItem("user", filteredUser[0]);
    //         return setUser(filteredUser[0]);
    //     }
    //     setErrorMessage("Username or Password Incorrect");
    // }
    
    const login = async () => {
        
        const loginData = {
            userName: userName,
            password: password
        }
        try {
            
            // const url = `http://localhost:5000/commissionmatrix/${commission.SaleshouseID}/${commission.ChannelCode}`;
            const url = `/api/user/login`;
            const res = await fetch(url, {
                method: "post",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(loginData)
            })
            const data =  await res.json();
            
            setUser(data)
              
            // if (res.ok) {

            //     //setMissingCustomers(missingCustomers.filter(cust => cust.AgencyId != customer.AgencyId));
            //     // setCustomers([...customers, newItem]);
            // }   
        } catch (error){

            console.log(error)
            setErrorMessage("Username or Password Incorrect");
        }
    }


  return (
    <>
        <div className="login-form">

                <label className="login-label" htmlFor='username'>User Name</label>
                <input className="login-input" type="text" id='username' placeholder="User name" onChange={e => setUserName(e.target.value)}></input>
                <div></div>
                <div></div>

                <label lassName="login-label" htmlFor='password'>Password</label>
                <input lassName="login-input" type="password" id='password' placeholder='********' onChange={e => setPassword(e.target.value)}></input>

            <button className='btn-login' id='signin' onClick={login}>Sign in</button>
            <div className="error-message">{errorMessage}</div>
        </div>
    
    </>);
}
