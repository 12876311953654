import React from 'react';
import logo from './digitex_logo.png';
import './Header.css';
import Navbar from './Navbar';

export default function Header({ user, logout }) {
  return (<div className="header-container">
      <div className="spacer"></div>
      <div className="logo">
        <img src={logo}></img><span>Accounts</span>
      </div>
      <div className="nav-container">
        <Navbar user={user} logout={logout}></Navbar>
      </div>
      {user != null ? <div className="logout" onClick={logout}>Sign out {user.companyName}</div> : <div className="spacer"></div>}
      
      
  </div>);
}
