import React, { useEffect , useState } from 'react'
import { NavLink } from 'react-router-dom';
import './ReceiptsAnalysis.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import SalesStatementBatch from './SalesStatementBatch';
import Modal from './Modal';

//<NavLink className={navData => navData.isActive ? "active nav-header" : 'nav-header'} to={to} >{label}</NavLink>
//to="/receipts"

export default function ReceiptsAnalysis({ receipts, receiptInvoices, setReceiptInvoices, selectedInvoice, setSelectedInvoice, user }) {
    const [analysis, setAnalysis] = useState([]);
    const [buyers, setBuyers] = useState([]);
    const [pdfFormOpen, setPDFFormOpen] = useState(false)
    const [showZeroes, setShowZeroes] = useState(true)
    
    var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      
    //   useEffect(()=>{

    //   },[receiptInvoices])
      
    //   const Analyse = () => {
    //       const buyerArray = [ ... new Set(receiptInvoices.map(ri => ri.agencyName))].sort();
    //       const anal = buyerArray.forEach(buyer => receiptInvoices.map)
    //   }

    useEffect(() => {
      const fetchData = async () => {
        if (!user) return setAnalysis([]);
        try {
          // const url = `http://localhost:5000/receiptsageing/${user.companyId}`
          const url = `/api/receiptsageing/${user.companyId}`
          const res = await fetch(url)
          const data =  await res.json();
          console.log("receipts ageing", data.receiptsAnalysis)
          setAnalysis(data.receiptsAnalysis)
        } catch (error) {
          console.log(error)
        }
      };
  
      fetchData();
    }, []);

    // useEffect(()=>{
    //   getAgeing();
    // },[])


    // const getAgeing = async () => {
    //   if (!user) return setAnalysis([]);
    //   try {
    //     const url = `http://localhost:5000/receiptsageing/${user.companyId}`
    //     const res = await fetch(url)
    //     const data =  await res.json();
    //     console.log("receipts ageing", data.receiptsAnalysis)
    //     setAnalysis(data.receiptsAnalysis)
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }


    //Excel export link------------------

    // const excelLink = 'http://localhost:5000/api/receiptsageing/excel/' //test
    const excelLink = '/api/receiptsageing/excel/' //live

    function createPDFs() {
      setPDFFormOpen(true)
    }

    function closePDFForm() {
      setPDFFormOpen(false)
    }

    return (
    <>
      <div className="analysis-container">
        <span className="title">Aged Debtors</span>
        <a href={`${excelLink}${user.companyId}`}> <FontAwesomeIcon icon={faFileExcel} /></a>
        <div className='actions' ><button onClick={(e) => setShowZeroes(!showZeroes)}>{showZeroes ? 'Hide Zero Values' : 'Show Zero Values'}</button><button onClick={createPDFs}>Create Sales Statement PDFs</button></div>
        <div className="analysis analysis-header">
            <span className="left-align">Buyer</span><span className="right-align ccy">Current Month</span><span className="right-align ccy">1 Month</span><span className="right-align ccy">2 Months</span><span className="right-align ccy">3 Months+</span><span className="right-align ccy">Unallocated</span>
        </div>
        <div className="analysis-entries-container">
          {showZeroes ? 
        analysis.map(cust => <NavLink to={'/receipts/' + cust.AgencyID}><div key={cust.AgencyID} className="analysis analysis-row"><span className="agency-name analysis-data">{cust.AgencyName}</span><span className="right-align ccy analysis-data">{formatter.format(cust.Month_0)}</span><span className="right-align ccy analysis-data">{formatter.format(cust.Month_1)}</span><span className="right-align ccy analysis-data">{formatter.format(cust.Month_2)}</span><span className="right-align ccy analysis-data">{formatter.format(cust.Month_3)}</span><span className="right-align ccy analysis-data">{formatter.format(cust.OpenReceipts)}</span></div></NavLink>)
        : analysis.filter(cust => cust.Month_0 != 0 || cust.Month_1 != 0 || cust.Month_2 != 0 || cust.Month_3 != 0 || cust.OpenReceipts != 0).map(cust => <NavLink to={'/receipts/' + cust.AgencyID}><div key={cust.AgencyID} className="analysis analysis-row"><span className="agency-name analysis-data">{cust.AgencyName}</span><span className="right-align ccy analysis-data">{formatter.format(cust.Month_0)}</span><span className="right-align ccy analysis-data">{formatter.format(cust.Month_1)}</span><span className="right-align ccy analysis-data">{formatter.format(cust.Month_2)}</span><span className="right-align ccy analysis-data">{formatter.format(cust.Month_3)}</span><span className="right-align ccy analysis-data">{formatter.format(cust.OpenReceipts)}</span></div></NavLink>)}
        </div> 
        <div className="analysis analysis-footer">
            <span>Totals</span><span className="right-align ccy">{formatter.format(analysis.reduce((previousValue, currentValue) => previousValue + currentValue.Month_0,0))}</span><span className="right-align ccy">{formatter.format(analysis.reduce((previousValue, currentValue) => previousValue + currentValue.Month_1,0))}</span><span className="right-align ccy">{formatter.format(analysis.reduce((previousValue, currentValue) => previousValue + currentValue.Month_2,0))}</span><span className="right-align ccy">{formatter.format(analysis.reduce((previousValue, currentValue) => previousValue + currentValue.Month_3,0))}</span><span className="right-align ccy">{formatter.format(analysis.reduce((previousValue, currentValue) => previousValue + currentValue.OpenReceipts,0))}</span>
        </div>
      </div>
      <Modal open={pdfFormOpen}  onClose={() => setPDFFormOpen(false)}>
        <SalesStatementBatch closeModal={() => setPDFFormOpen(false)} saleshouseId={user.companyId} analysis={analysis} ></SalesStatementBatch>
      </Modal>
    </>
    
  )
}


