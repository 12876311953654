import React, {useState} from 'react'
import './Customer.css';
import CustomerDisplay from './CustomerDisplay';
import Modal from './Modal';

export default function Customer({customer, setDataChanged}) {
  const [agencyId] = useState(customer.agencyId)
  const [agencyName, setAgencyName] = useState(customer.agencyName)
  const [contactName, setContactName] = useState(customer.contactName);
  const [contactEmail, setContactEmail] = useState(customer.contactEmail)
  const [address1, setAddress1] = useState(customer.address1);
  const [address2, setAddress2] = useState(customer.address2);
  const [address3, setAddress3] = useState(customer.address3);
  const [address4, setAddress4] = useState(customer.address4);
  const [postcode, setPostcode] = useState(customer.postcode);
  const [isOpen, setIsOpen] = useState(false);

  const UpdateCustomer = () => {
    const data = { agencyId: agencyId, saleshouseId: customer.saleshouseId, agencyName: agencyName, contactName: contactName, contactEmail: contactEmail,
              Address1: address1, Address2: address2, Address3: address3, Address4: address4, Postcode: postcode};

    // fetch('http://localhost:5000/customers', {
      fetch('/api/customers', {
      method: 'PUT', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }
  const TestFunction = () => {
    setIsOpen(false)
    
  }
  const CloseFunction = () => {
    setIsOpen(false)
    

  }

  const addressFields = [];
  if (customer.Address1) addressFields.push(customer.Address1);
  if (customer.Address2) addressFields.push(customer.Address2);
  if (customer.Address3) addressFields.push(customer.Address3);
  if (customer.Address4) addressFields.push(customer.Address4);
  let address = addressFields.join(', ');
  if (customer.Postcode) address = address + ' '  + customer.Postcode;

  return (
    // <div className="customer">
    //     <div className="customer">
    //     <input className="customer-info" value={agencyName} type="text" placeholder={customer.AgencyName} onChange={e => setAgencyName(e.target.value)}></input>
    //     <input className="customer-info" value={contactName} type="text" placeholder="contact name" onChange={e => setContactName(e.target.value)}></input>
    //     <input className="customer-info" value={contactEmail} type="text" placeholder="contact email" onChange={e => setContactEmail(e.target.value)}></input>
    //     <input className="customer-info" value={address1} type="text" placeholder={customer.Address1} onChange={e => setAddress1(e.target.value)}></input>
    //     <input className="customer-info" value={address2} type="text" placeholder={customer.Address2} onChange={e => setAddress2(e.target.value)}></input>
    //     <input className="customer-info" value={address3} type="text" placeholder={customer.Address3} onChange={e => setAddress3(e.target.value)}></input>
    //     <input className="customer-info" value={address4} type="text" placeholder="" onChange={e => setAddress4(e.target.value)}></input>
    //     <input className="customer-info" value={postcode} type="text" placeholder={customer.Postcode} onChange={e => setPostcode(e.target.value)}></input>
    //     <button className="customer-add" onClick={e => UpdateCustomer()}>Update {customer.agencyId}</button>
        
    // </div>
    //     {/* <span className="customer-text">{customer.agencyName}</span>
    //     <span className="customer-text">{customer.contactName}</span>
    //     <span className="customer-text">{customer.contactEmail}</span>
    //     <span className="customer-text">{customer.address1}</span>
    //     <span className="customer-text">{customer.address2}</span>
    //     <span className="customer-text">{customer.address3}</span>
    //     <span className="customer-text">{customer.address4}</span>
    //     <span className="customer-text">{customer.postcode}</span> */}
    // </div>
    // <div className="customer missing customer-container" onClick={() => setIsOpen(true)}>
    <>
      <div className="customer missing customer-container" onClick={() => setIsOpen(true)}>
        
        <div className="customer-info agency-id">{customer.AgencyId}</div>
        <div className="customer-info agency-name">{customer.AgencyName}</div>
        {/* <div className="customer-info contact-name">{customer.ContactName}</div>
        <div className="customer-info contact-email">{customer.ContactEmail}</div> */}
        <div className="customer-info address">{address}</div>
      
      </div>
      <Modal open={isOpen}  onClose={() => setIsOpen(false)} >
          <CustomerDisplay customer={customer} missing={false} agencyName={customer.AgencyName} closeModal={() => setIsOpen(false)} setDataChanged={setDataChanged}></CustomerDisplay>
      </Modal>
    </>
    
  )
}
