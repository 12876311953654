import React from 'react'
import './SearchResult.css'

export default function SearchResult({ record }) {

  var formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return (
    <div className="search-result-invoice-receipt">
        <span>{record.invoiceNumber} </span><span> {record.amalgamatedInvoice} </span><span> {formatter.format(record.amount)} </span><span> {formatter.format(record.amountOutstanding)} </span><span> {new Date(record.invoiceDate).toLocaleDateString('en-GB')} </span>
        <span> {record.agencyName} </span><span> {record.OrderDescription} </span>
    </div>
  )
}
