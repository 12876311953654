import React from 'react'
import './AllocationList.css'
import {useState, useEffect} from 'react'
import AllocationsDisplay from './AllocationsDisplay'

export default function AllocationList({invoiceId, receiptId, setDataChanged}) {
    const [allocations, setAllocations] = useState([])

    

    useEffect(() => {
        const FetchData = async () => {
            try {
                const url = invoiceId ? `/api/receiptallocations/invoicenumber/${invoiceId}` : receiptId ? `/api/receiptallocations/receipt/${receiptId}` : ''
                console.log('url', url);
                const res = await fetch(url);
                const data = await res.json();
                console.log("data", data)
                console.log("receipt allocations", data.receiptAllocations)
                setAllocations(data.receiptAllocations)
            } catch (error) {
                console.log(error)
            }
            
        } 

        FetchData()
    },[invoiceId, receiptId])

    if (!allocations) return 'Loading....'
    if (allocations && allocations.length == 0) return 'Loading...'
    
    return (
        <div className='allocation-list'>
            {/* {allocations.map(alloc => <div>{alloc.Amount}</div>)} */}
            {allocations.map(alloc => <AllocationsDisplay allocation={alloc} setDataChanged={setDataChanged} />)}
        </div>
    )
}

